import { Alert, Box, Button, Checkbox, Chip, Dialog, DialogContent, DialogProps, DialogTitle, Divider, FormControl, Grid, Input, InputLabel, ListItemIcon, ListItemText, MenuItem, MobileStepper, Select, TextField, Typography } from '@mui/material';
import { BusinessPartnerInfo, Display, DroneInfo, OperationPartnerInfo, PartnerKind, Port, Purpose, Specific, UserInfo } from 'adoms-common-lib';
import axios from 'axios';
import React, { useEffect, useLayoutEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useUserInfoContext } from '../../common/UserContext';
import { APIConnector } from '../../connector/APIConnector';

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default
    },
    formControl: {
        minWidth: 120,
        whiteSpace: 'nowrap',
    },
    fdFormControl: {
        minWidth: 120,
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    fdTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 250,
    },
    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    stepper: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

type NewFlightCreateDialogProps = {
    isOpen: boolean,
    closeHandler: () => void,
    arrival?: string,
    departure?: string,
    businessPartnerId?: string,
    orderId?: string,
    purpose?: Purpose,
    specificList?: Specific[]
}

type InputValueType = {
    flightDate: string,
    std: string,
    sta: string,
    arrival: string,
    departure: string,
    fd: string,
    drone: string,
    payload: string,
    businessPartnerId: string,
    orderId: string,
    purpose: Purpose,
    specificList?: Specific[]
}


export const NewFlightCreateDialog: React.FC<NewFlightCreateDialogProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { classes } = useStyles();
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const [activeStep, setActiveStep] = React.useState(location.pathname === "/orderdetailview" ? 1 : 0);

    const [value, setValue] = React.useState({
        flightDate: Display.getNowDateWithString(),
        std: "",
        sta: "",
        arrival: props.arrival ?? "",
        departure: props.departure ?? "",
        fd: "",
        drone: "",
        payload: "",
        businessPartnerId: props.businessPartnerId ?? "",
        orderId: props.orderId,
        purpose: Purpose.Transport,
        specificList: [Specific.OutOfSight]
    } as InputValueType);


    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isNextDisable, setNextDisable] = React.useState(true);
    const [isBackDisable, setBackDisable] = React.useState(location.pathname === "/orderdetailview");

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNextDisable(isNextDisableCheck(activeStep + 1, value));
        setBackDisable(false);
    };

    const handleBack = () => {
        const handleBackLimit = location.pathname === "/orderdetailview" ? 1 : 0
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setNextDisable(isNextDisableCheck(activeStep - 1, value));
        setBackDisable((activeStep - 1) === handleBackLimit);
        setErrorMessage(undefined);
    };

    /**
     * ダイアログをクローズする
     */
    const handleNewFlightDialogClose = () => {
        props.closeHandler();
    };


    /**
     * 配送依頼詳細から連携された出発地と到着地を設定するハンドラ
     */
    const handleSetOrderDepArv = () => {
        setNextDisable(isNextDisableCheck(activeStep, value));
        setBackDisable(activeStep > 0);
    };


    /**
     * パートナーIDが変わった時のハンドラ
     * @param event 
     */
    const handleBusinessChangePartnerID = (newValue: string) => {
        value.businessPartnerId = newValue;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };

    /**
     * 到着地が変わった時のハンドラ
     * @param event 
     */
    const handleChangeArrival = (newValue: string) => {
        value.arrival = newValue;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };


    /**
     * 出発地が変わったときのハンドラ
     * @param event 
     */
    const handleChangeDeparture = (newValue: string) => {
        value.departure = newValue;
        setNextDisable(isNextDisableCheck(activeStep, value));
    }


    /**
     * フライト日が変わった時のハンドラ
     * @param newValue
     */
    const handleChangeFlightDate = (newValue: string) => {
        value.flightDate = newValue;
        setNextDisable(isNextDisableCheck(activeStep, value));
    }

    /**
     * 目的が変わった時のハンドラ
     * @param newValue
     */
    const handleChangePurpose = (newValue: Purpose) => {
        value.purpose = newValue;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };

    /**
     * 特定飛行が変わった時のハンドラ
     * @param newValue
     */
    const handleChangeSpecificList = (newValue: Specific[]) => {
        value.specificList = newValue;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };

    /**
     * STAが変わった時のハンドラ
     * @param event 
     */
    const handleChangeSTD = (std: string) => {
        value.std = std;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };


    /**
     * STDが変わった時のハンドラ
     * @param event 
     */
    const handleChangeSTA = (sta: string) => {
        value.sta = sta;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };


    /**
     * ドローンが変わったときのハンドラ
     * @param event 
     */
    const handleChangeDrone = (drone: string) => {
        value.drone = drone;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };

    /**
     * ドローンが変わったときのハンドラ
     * @param event 
     */
    const handleChangePayload = (payload: string) => {
        value.payload = payload;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };


    /**
     * FDが変わった時のハンドラ
     * @param fdSub 
     */
    const handleChangeFD = (fdSub: string) => {
        value.fd = fdSub;
        setNextDisable(isNextDisableCheck(activeStep, value));
    };

    /**
     * フライト作成処理を行い、フライト詳細ページに移動する
     * @param event
     */
    const handleToDetailPage = (event: any) => {
        setNextDisable(true);
        setBackDisable(true);
        let departure: string = value.departure;
        let arrival: string = value.arrival;
        let orderId: string = value.orderId;
        let businessPartnerId: string = value.businessPartnerId;

        if (value.departure === "" && typeof props.departure !== "undefined") {
            departure = props.departure
        };

        if (value.arrival === "" && typeof props.arrival !== "undefined") {
            arrival = props.arrival
        };

        if (value.orderId === "" && typeof props.orderId !== "undefined") {
            orderId = props.orderId
        };

        if (value.businessPartnerId === "" && typeof props.businessPartnerId !== "undefined") {
            businessPartnerId = props.businessPartnerId
        };

        const fetchData = async () => {
            let apigw: APIConnector = APIConnector.instance;
            let result: Promise<void | string> = apigw.putFlight(
                value.flightDate,
                departure,
                arrival,
                value.drone,
                value.std,
                value.sta,
                value.fd,
                Number(value.payload),
                value.purpose,
                value.specificList,
                orderId,
                businessPartnerId
            );
            result.then((value: void | string) => {
                setNextDisable(false);
                setBackDisable(false);
                navigate("/flightoperationview", {
                    state: { id: value as string },
                })
            }).catch((error) => {
                console.log(error);
                setNextDisable(false);
                setBackDisable(false);
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    setErrorMessage("フライト作成の権限がありません");
                } else {
                    setErrorMessage("新規フライトの作成中にエラーが発生しました。再試行してもエラーが発生する場合、システム管理者へご連絡ください");
                }
            });
        }
        fetchData();
    }

    return (
        <div className={classes.root}>
            {/** フライト新規作成ダイアログ */}
            <Dialog
                open={props.isOpen}
                onClose={handleNewFlightDialogClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">フライト情報入力<Divider /></DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    {
                        errorMessage ? (
                            <Alert severity="error">{errorMessage}</Alert>
                        ) : null
                    }

                    {activeStep === 0 ? (
                        <Page1 value={value}
                            onChangeArrival={handleChangeArrival}
                            onChangeDeparture={handleChangeDeparture}
                            onChangeFlightDate={handleChangeFlightDate}
                            onSetOrderDepArv={handleSetOrderDepArv}
                            onChangeBusinessPartnerID={handleBusinessChangePartnerID}
                            onChangePurpose={handleChangePurpose}
                            onChangeSpecificList={handleChangeSpecificList}
                            departure={props.departure}
                            arrival={props.arrival}
                            businessPartnerId={props.businessPartnerId}
                        />) : null}
                    {activeStep === 1 ? (
                        <Page2 value={value}
                            onChangeDrone={handleChangeDrone}
                            onChangePayload={handleChangePayload}
                            businessPartnerId={props.businessPartnerId}
                        />) : null}
                    {activeStep === 2 ? (
                        <Page3 value={value}
                            onChangeSTD={handleChangeSTD}
                            onChangeSTA={handleChangeSTA}
                        />) : null}
                    {activeStep === 3 ? (
                        <Page4 value={value}
                            onChangeFD={handleChangeFD}
                            businessPartnerId={props.businessPartnerId}
                        />) : null}


                    <MobileStepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={activeStep}
                        className={classes.stepper}
                        nextButton={
                            activeStep !== 3 ?
                                (<Button color="primary" size="small" onClick={handleNext} disabled={isNextDisable}>次へ</Button>) :
                                (<Button color="secondary" size="small" onClick={handleToDetailPage} disabled={isNextDisable}>確定</Button>)
                        }
                        backButton={
                            <Button color="primary" size="small" onClick={handleBack} disabled={isBackDisable || activeStep === 0}>戻る</Button>
                        }
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}


type Page1Props = {
    businessPartnerId?: string,
    arrival?: string,
    departure?: string,
    value: {
        flightDate: string,
        std: string,
        sta: string,
        arrival: string,
        departure: string,
        fd: string,
        drone: string,
        payload: string,
        businessPartnerId: string,
        orderId?: string,
        purpose: Purpose,
        specificList?: Specific[]
    }
    onChangeArrival: (newValue: string) => void
    onChangeDeparture: (newValue: string) => void
    onChangeFlightDate: (newValue: string) => void
    onSetOrderDepArv: () => void
    onChangeBusinessPartnerID: (newValue: string) => void
    onChangePurpose: (newValue: Purpose) => void
    onChangeSpecificList: (newValue: Specific[]) => void
}

/**
 * パートナーIDとフライト日、発着ポートを選択するダイアログ
 * @param props 
 * @returns 
 */
const Page1: React.FC<Page1Props> = (props) => {
    const { classes } = useStyles();
    const userInfoContext = useUserInfoContext();
    const [departurePortId, setDeparturePordId] = React.useState(props.departure ?? props.value.departure);
    const [arrivalPortId, setArrivalPortId] = React.useState(props.arrival ?? props.value.arrival);
    const [flightDate, setFlightDate] = React.useState(props.value.flightDate);
    const [businessPartnerId, setBusinessPartnerId] = React.useState(props.businessPartnerId ?? props.value.businessPartnerId);
    const [purpose, setPurpose] = React.useState(props.value.purpose);
    const [specificList, setSpecificList] = React.useState(props.value.specificList);
    const [isError, setError] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState(userInfoContext.userInfo);

    /**
     * すべてのポートを格納するstate
     */
    const [allPortListState, setAllPortList] = React.useState<Port[]>([]);
    /**
     * すべてのパートナーを格納するstate
     */
    const [businessPartnerInfoList, setBusinessPartnerInfoList] = React.useState<BusinessPartnerInfo[]>([]);
    /**
     * 出発地と到着地
     */
    const [departurePortList, setDeparturePortList] = React.useState<Port[]>([]);
    const [arrivalPortList, setArrivalPortList] = React.useState<Port[]>([]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const displaySpecificList = [
        Specific.Airport,
        Specific.DID,
        Specific.Dangerous,
        Specific.Drop,
        Specific.Event,
        Specific.Night,
        Specific.OutOfSight,
        Specific.Over150m,
        Specific.Within30M
    ];

    useEffect(() => {
        setUserInfo(userInfoContext.userInfo);
        setError(userInfoContext.isError);
    }, [userInfoContext]);

    useLayoutEffect(() => {
        //ポート情報を取得
        const fetchPortData = async () => {
            if (userInfo) {
                //backendからポート一覧を取得
                const c: APIConnector = APIConnector.instance;
                await c.getPortListForOperation().then(allPortList => {
                    setError(false);
                    setAllPortList(allPortList);

                    if (userInfo.userPartnerInfo.partnerKind === PartnerKind.Operation) {
                        let visibleBusinessPartnerInfoList = (userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).visibleBusinessPartnerInfoList;
                        setBusinessPartnerInfoList(visibleBusinessPartnerInfoList);

                        const primaryBusinessPartnerId = businessPartnerId !== "" ? businessPartnerId : visibleBusinessPartnerInfoList[0].businessPartnerId;

                        setBusinessPartnerId(primaryBusinessPartnerId);

                        //パートナー情報に一致する出発ポート情報を設定
                        let departurePortListParam = allPortList.filter(v => {
                            return v.businessPartnerId === "" || v.businessPartnerId === primaryBusinessPartnerId;
                        })
                        setDeparturePortList(departurePortListParam);
                        let useLayoutDeparturePortId = departurePortId !== "" ? departurePortId : departurePortListParam[0].id;
                        setDeparturePordId(useLayoutDeparturePortId)
                        const depaturePort: Port = allPortList.find((port) => port.id === useLayoutDeparturePortId
                            && port.businessPartnerId === primaryBusinessPartnerId) as Port;

                        //パートナー情報に一致する到着ポート情報を設定
                        let arrivalPortListParam = allPortList.filter(arrPort => {
                            return arrPort.businessPartnerId === depaturePort.businessPartnerId && depaturePort.destinationPortList.some(v2 => {
                                return v2 === arrPort.id
                            });
                        });
                        setArrivalPortList(arrivalPortListParam);
                        let useLayoutArrivalPortId = arrivalPortId !== "" ? arrivalPortId : arrivalPortListParam[0].id;
                        setArrivalPortId(useLayoutArrivalPortId);
                        const arrivalPort: Port = allPortList.find((port) => port.id === useLayoutArrivalPortId
                            && port.businessPartnerId === primaryBusinessPartnerId) as Port;

                        if (departurePortId != null && arrivalPortId != null) {
                            props.onSetOrderDepArv();
                        }

                        props.onChangeDeparture(depaturePort.id);
                        props.onChangeArrival(arrivalPort.id);
                        props.onChangeBusinessPartnerID(primaryBusinessPartnerId);
                    } else {
                        // ビジネスパートナーはフライト作成ボタンが表示されないため、ここには到達しない想定
                        throw new Error("ビジネスパートナーはフライトを作成できません");
                    };

                }).catch(error => {
                    console.log("error:" + error.message);
                    setError(true);
                });
            }
        }
        fetchPortData();
    }, [userInfo]);

    /**
     * 配送日が変わった時のハンドラ
     * @param event 
     */
    const handleChangeFlightDate = (event: any) => {
        setFlightDate(event.target.value);
        props.onChangeFlightDate(event.target.value);
    };

    /**
     * パートナーが変わった時のハンドラ
     * @param event 
     */
    const handleChangeBusinessPartnerID = (event: any) => {
        const newBusinessPartnerId = event.target.value
        setBusinessPartnerId(newBusinessPartnerId);
        let newDeparturePortMap = allPortListState.filter(v => {
            return newBusinessPartnerId === "" || v.businessPartnerId === newBusinessPartnerId;
        });
        setDeparturePortList(newDeparturePortMap);

        setDeparturePordId(newDeparturePortMap[0].id);
        setArrivalPortId("");

        const arrivalmap = [] as Port[];
        for (const portId of newDeparturePortMap[0].destinationPortList) {
            if (!newDeparturePortMap.some((v) => v.id === portId)) {
                console.log("エラー:ポートID(" + portId + ")がdynamodbに登録されていないため、到着ポートが設定できませんでした");
                continue;
            }
            console.log(portId)
            arrivalmap.push(newDeparturePortMap.find((v) => v.id === portId) as Port);
        }
        //到着ポートを設定
        setArrivalPortList(arrivalmap);
        //出発地が変更された場合、到着地をクリアする
        setArrivalPortId("");

        props.onChangeArrival("");
        props.onChangeDeparture(newDeparturePortMap[0].id);
        props.onChangeBusinessPartnerID(newBusinessPartnerId);
    };

    /**
     * 出発地が変更されたときのハンドラ
     * @param event 
     */
    const handleChangeDeparturePulldown = (event: any) => {
        setDeparturePordId(event.target.value);

        if (!departurePortList.some((v) => v.id === event.target.value)) {
            console.log("エラー:ポートID(" + props.value.departure + ")がdynamodbに登録されていないため、到着ポートが設定できませんでした");
            return;
        }
        let depPort: Port = departurePortList.find((v) => v.id === event.target.value) as Port;
        if (depPort.businessPartnerId !== businessPartnerId) {
            setBusinessPartnerId(depPort.businessPartnerId);
            props.onChangeBusinessPartnerID(depPort.businessPartnerId);
        }
        setDeparturePortList(allPortListState.filter(v => {
            return v.businessPartnerId === "" || v.businessPartnerId === businessPartnerId;
        }));
        props.onChangeDeparture(event.target.value);

        const arrivalmap = [] as Port[];
        for (const portId of depPort.destinationPortList) {
            if (!departurePortList.some((v) => v.id === portId)) {
                console.log("エラー:ポートID(" + portId + ")がdynamodbに登録されていないため、到着ポートが設定できませんでした");
                continue;
            }
            arrivalmap.push(departurePortList.find((v) => v.id === portId) as Port);
        }
        //到着ポートを設定
        setArrivalPortList(arrivalmap);
        //出発地が変更された場合、到着地をクリアする
        setArrivalPortId("");
        props.onChangeArrival("");

    };

    /**
     * 到着地が変わった時のハンドラ
     * @param event 
     */
    const handleChangeArrivalPulldown = (event: any) => {
        setArrivalPortId(event.target.value);
        props.onChangeArrival(event.target.value);
    };

    /**
     * 飛行目的が変わった時のハンドラ
     * @param event 
     */
    const handleChangePurpose = (event: any) => {
        setPurpose(event.target.value);
        props.onChangePurpose(event.target.value);
    };

    /**
     * 特定飛行が変わった時のハンドラ
     * @param event 
     */
    const handleChangeSpecificList = (event: any) => {
        setSpecificList(event.target.value);
        props.onChangeSpecificList(event.target.value);
    };

    /**
     * 特定飛行が削除された時
     * @param specificParam 
     */
    const chipDelete = (specificParam: string) => {
        const specificListParam = [...specificList!.filter(
            specific => specific !== specificParam)];
        setSpecificList(specificListParam);
        props.onChangeSpecificList(specificListParam);
    };

    return (
        <Grid container direction="column" spacing={2} sx={{ padding: 1 }}>
            {isError ? <Grid item>
                <Alert severity='error'>
                    パートナー情報、ポート情報が取得できませんでした。
                </Alert>
            </Grid> : undefined}
            <Grid item>
                <FormControl variant="standard" className={classes.formControl}>
                    <TextField
                        variant="standard"
                        id="date"
                        label="配送日"
                        type="date"
                        value={flightDate}
                        className={classes.textField}
                        onChange={handleChangeFlightDate}
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel className={classes.select} id="demo-simple-select-outlined-label">ビジネスパートナー</InputLabel>
                    <Select
                        variant="standard"
                        className={classes.textField}
                        labelId="businessPartnerID"
                        id="businessPartnerID"
                        value={businessPartnerId}
                        onChange={handleChangeBusinessPartnerID}
                        label="ビジネスパートナー選択"
                        disabled={props.businessPartnerId !== undefined}>
                        {businessPartnerInfoList.map((businessPartnerInfo, i) => (
                            <MenuItem selected={i === 0}
                                key={businessPartnerInfo.businessPartnerId}
                                value={businessPartnerInfo.businessPartnerId}>
                                {businessPartnerInfo.businessPartnerName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item container>
                <Grid item>
                    <FormControl variant="standard" className={classes.formControl}>
                        <InputLabel className={classes.select} id="demo-simple-select-outlined-label">出発</InputLabel>
                        <Select
                            variant="standard"
                            className={classes.textField}
                            labelId="dep"
                            id="dep"
                            value={departurePortId}
                            onChange={handleChangeDeparturePulldown}
                            label="出発">
                            {departurePortList.map((port, i) => (
                                <MenuItem selected={(departurePortId === port.id)} key={port.id} value={port.id}>{port.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl variant="standard" className={classes.formControl}>
                        <InputLabel className={classes.select} id="demo-simple-select-outlined-label">到着</InputLabel>
                        <Select
                            variant="standard"
                            className={classes.textField}
                            labelId="arv"
                            id="arv"
                            value={arrivalPortId}
                            onChange={handleChangeArrivalPulldown}
                            label="到着">
                            {arrivalPortList.map((port, i) =>
                                <MenuItem selected={(arrivalPortId === port.id)} key={port.id} value={port.id}>{port.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item>
                <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel className={classes.select} id="purpose-select-outlined-label">飛行目的</InputLabel>
                    <Select
                        variant="standard"
                        className={classes.textField}
                        labelId="purpose"
                        id="purpose"
                        value={purpose}
                        onChange={handleChangePurpose}
                        label="飛行目的">
                        <MenuItem key="transport" value={Purpose.Transport}>{Purpose.Transport}</MenuItem>
                        <MenuItem key="enviromentalSurvey" value={Purpose.EnviromentalSurvey}>{Purpose.EnviromentalSurvey}</MenuItem>
                        <MenuItem key="testFlight" value={Purpose.TestFlight}>{Purpose.TestFlight}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel className={classes.select} id="specificList-select-outlined-label">特定飛行</InputLabel>
                    <Select
                        sx={{
                            marginLeft: 1,
                            marginRight: 1,
                            minWidth: "200px"
                        }}
                        variant="standard"
                        labelId="specificList-multiple-checkbox-label"
                        id="specificList-multiple-checkbox"
                        label="特定飛行"
                        multiple
                        value={specificList}
                        onChange={handleChangeSpecificList}
                        renderValue={(selected) => (
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}>
                                {(selected as string[]).map((value) => (
                                    <Chip
                                        sx={{ margin: 0.5 }}
                                        size='small'
                                        key={value}
                                        label={value}
                                        onDelete={(event) => chipDelete(value)}
                                        onMouseDown={(event) => { event.stopPropagation(); }} />
                                ))}
                            </div>
                        )}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                }
                            }
                        }}
                        input={<Input />}>
                        {displaySpecificList.map((specific: Specific) => {
                            const labelId = `transfer-list-item-${specific}-label`;
                            return (
                                <MenuItem key={specific} value={specific}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={specificList?.findIndex(checkedSpecific => checkedSpecific === specific) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={specific} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}




function isNextDisableCheck(step: number, val: {
    flightDate: string;
    std: string;
    sta: string;
    arrival: string;
    departure: string;
    fd: string;
    drone: string;
    payload: string;
    businessPartnerId: string;
    orderId?: string;
}): boolean {
    if (step === 0) {
        return val.flightDate === "" || val.arrival === "" || val.departure === "" || val.businessPartnerId === "";
    } else if (step === 1) {
        return val.drone === "" || val.payload === "";
    } else if (step === 2) {
        return val.std === "" || val.sta === "";
    } else if (step === 3) {
        return val.fd === "";
    } else {
        return true;
    }
}




interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref,
) {
    const { onChange, ...other } = props;


    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            inputMode='decimal'
            valueIsNumericString
            thousandSeparator
            allowNegative={false}
            suffix="g"
        />
    );
});



type Page2Props = {
    value: {
        flightDate: string,
        std: string,
        sta: string,
        arrival: string,
        departure: string,
        fd: string,
        drone: string,
        payload: string,
        businessPartnerId: string,
        orderId?: string,
    }
    onChangeDrone: (drone: string) => void
    onChangePayload: (paloady: string) => void
    businessPartnerId?: string
}



const Page2: React.FC<Page2Props> = (props) => {
    const { classes } = useStyles();
    const [droneInfoList, setDroneInfoList] = React.useState<DroneInfo[]>([]);
    const [drone, setDrone] = React.useState(props.value.drone as string);
    const [payload, setPayload] = React.useState(props.value.payload as string);
    const [businessPartnerId, setBusinessPartnerId] = React.useState(props.businessPartnerId ?? props.value.businessPartnerId);
    const result = droneInfoList.find((v) => v.registrationId === drone);
    const selecedDrone = (result !== undefined) ? result : {
        registrationId: "",
        img1: "",
        kind: "",
        label: "",
        max_flight_minutes: 0,
        preparation_minutes: 0,
        payload: 9999999, //デフォルトを0にすると一瞬最大積載重量がエラー表示されるため、あり得ない重量を数字をデフォルトとする
        remarks: "",
        sort: 0,
    };

    useEffect(() => {
        //ドローン情報を取得
        const fetchDroneData = async () => {
            let c: APIConnector = APIConnector.instance;
            let allDroneInfoList = await c.getDroneList();
            let droneInfoListInFetchDroneData: DroneInfo[] = [];
            for (let droneInfo of allDroneInfoList) {
                if (droneInfo.businessPartnerInfoList.some(businessPartnerInfo => {
                    return businessPartnerInfo.businessPartnerId === businessPartnerId
                })) {
                    // ポート選択の際に選んだビジネスパートナーIDを持っているドローンのみ表示する
                    droneInfoListInFetchDroneData.push(droneInfo);
                };
            };
            setDroneInfoList(droneInfoListInFetchDroneData);
            if (droneInfoListInFetchDroneData.length !== 0) {
                handleChangeDrone(droneInfoListInFetchDroneData[0]);
            };
        }
        fetchDroneData();
    }, []);


    /**
     * 選択したドローンが変わった時のイベント処理用ハンドラ
     * @param event 
     */
    const handleChangeDroneEvent = (event: any) => {
        const selectedDrone = droneInfoList.filter((val) => {
            return val.registrationId === event.target.value;
        });
        handleChangeDrone(selectedDrone[0]);
    };

    /**
     * 選択したいドローンが変わった時の共通処理用ハンドラ
     * @param event 
     */
    const handleChangeDrone = (drone: DroneInfo) => {
        props.onChangeDrone(drone.registrationId);
        setDrone(drone.registrationId);
        //選択されたドローンのデフォルトペイロードも合わせて設定
        props.onChangePayload(drone.payload.toString());
        setPayload(drone.payload.toString());
    }


    /**
     * ペイロードが変わった時のハンドラ
     * @param event 
     */
    const handleChangePayload = (event: any) => {
        props.onChangePayload(event.target.value);
        setPayload(event.target.value);
    };

    return (
        <Grid container direction="column" spacing={2} sx={{ padding: 1 }}>
            <Grid item>
                <FormControl variant="standard" className={classes.formControl}>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={classes.select} id="demo-simple-select-outlined-label">使用機材</InputLabel>
                            <Select
                                variant="standard"
                                className={classes.textField}
                                labelId="drone"
                                onChange={handleChangeDroneEvent}
                                value={drone}
                                id="drone"
                                label="使用機材">
                                {droneInfoList.map((drone: DroneInfo, i: number) => (
                                    <MenuItem key={drone.registrationId} value={drone.registrationId}>{drone.label}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="最大積載重量"
                                onChange={handleChangePayload}
                                name="weight"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                value={payload}
                                error={Number(payload) > selecedDrone.payload}
                                disabled={drone === ""}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }} />
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item textAlign={"center"}>
                <Box
                    component="img"
                    src={selecedDrone.img1}
                    sx={{ maxWidth: "100%" }}
                />
            </Grid>
        </Grid>
    );

}



/**
 * STD/STA入力
 * @param props 
 */
function Page3(props: any) {
    const { classes } = useStyles();
    const [std, setSTD] = React.useState(props.value.std);
    const [sta, setSTA] = React.useState(props.value.sta);

    useEffect(() => {
        let systemTime = new Date();
        let nowHour = systemTime.getHours();
        let nowMinute = systemTime.getMinutes();
        let std = `${nowHour}:${nowMinute}`;

        handleChangeSTD(std);
    }, []);

    /**
     * STAが変わった時のイベント処理ハンドラ
     * @param event 
     */
    const handleChangeSTAEvent = (event: any) => {
        props.onChangeSTA(event.target.value);
        setSTA(event.target.value);
    };


    /**
     * STDが変わった時のイベント処理ハンドラ
     * @param event 
     */
    const handleChangeSTD = (std: string) => {
        props.onChangeSTD(std);
        setSTD(std);
    };


    return (
        <Grid container spacing={2} sx={{ padding: 1 }}>
            <Grid item>
                {/** STD */}
                <FormControl variant="standard" className={classes.formControl}>
                    <TextField
                        variant="standard"
                        id="time"
                        label="STD"
                        type="time"
                        onChange={(e) => {
                            handleChangeSTD(e.target.value)
                        }
                        }
                        defaultValue={std}
                        value={std}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }} />
                </FormControl>
            </Grid>
            <Grid item>
                {/** STA */}
                <FormControl variant="standard" className={classes.formControl}>
                    <TextField
                        variant="standard"
                        id="time"
                        label="STA"
                        type="time"
                        onChange={handleChangeSTAEvent}
                        defaultValue={sta}
                        value={sta}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }} />
                </FormControl>
            </Grid>
        </Grid>
    );
}


type Page4Props = {
    businessPartnerId?: string,
    value: {
        flightDate: string,
        std: string,
        sta: string,
        arrival: string,
        departure: string,
        fd: string,
        drone: string,
        payload: string,
        businessPartnerId: string,
        orderId?: string,
    }
    onChangeFD: (newValue: string) => void
}

/**
 * FD入力
 * @param props 
 */
const Page4: React.FC<Page4Props> = (props) => {
    const { classes } = useStyles();
    const userInfoContext = useUserInfoContext();
    const [fdMap, setFdMap] = React.useState<Map<string, UserInfo[]>>(new Map<string, UserInfo[]>());
    const [fdOperationPartnerIdList, setFdOperationPartnerIdList] = React.useState<string[]>([]);
    const [fdUserInfoList, setFdUserInfoList] = React.useState<UserInfo[]>([]);
    const [fdOperationPartnerId, setfdOperationPartnerId] = React.useState<string>("");
    const [fdUserSub, setFdUserSub] = React.useState<string>(props.value.fd);
    const [userInfo, setUserInfo] = React.useState(userInfoContext?.userInfo);
    const [businessPartnerId, setBusinessPartnerId] = React.useState(props.businessPartnerId ?? props.value.businessPartnerId);

    /**
     * オペレーションパートナーIDが変わった時のハンドラ
     * @param event 
     */
    const handleChangeOperationPartnerId = (event: any) => {
        setfdOperationPartnerId(event.target.value)
        setFdUserSub("");
        let fdUserInfoList = fdMap.get(event.target.value);
        if (typeof fdUserInfoList !== "undefined") {
            setFdUserInfoList(fdUserInfoList);
        };
    };

    /**
     * FDが変わった時のハンドラ
     * @param event 
     */
    const handleChangeFD = (event: any) => {
        props.onChangeFD(event.target.value);
        setFdUserSub(event.target.value);
    };


    useEffect(() => {
        //FD情報を取得
        const fetchFdData = async () => {
            let c: APIConnector = APIConnector.instance;
            let allFdUserInfoList = await c.getFlightDirectorList();
            let newFdMap = new Map<string, UserInfo[]>()
            for (let fdUserInfo of allFdUserInfoList) {
                /*
                * フライト作成で最初に選んだビジネスパートナーIDが、
                * FDの閲覧可能なビジネスパートナーIDリストに存在する場合、
                * FDをビジネスパートナーごとに分類し、fdMapに追加する
                */
                if ((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).
                    visibleBusinessPartnerInfoList.some(
                        visibleBusinessPartnerInfo => {
                            return visibleBusinessPartnerInfo.businessPartnerId
                                === businessPartnerId
                        })
                ) {
                    if (!newFdMap?.has((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId)) {
                        newFdMap.set((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId,
                            [fdUserInfo]);
                    } else {
                        newFdMap.get((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId)?.push(fdUserInfo);
                    };
                };
                setFdMap(newFdMap);
            };

            // fdMapのkey(オペレーションパートナーID)をfdOperationPartnerIdListにセットする
            setFdOperationPartnerIdList(Array.from(newFdMap.keys()));

            if (typeof userInfo !== "undefined") {
                // 自分のユーザー情報が取得できた場合

                if (typeof (userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId !== "undefined") {
                    // 自分のオペレーションパートナーIDのユーザー一覧を作成
                    let myPartnerIdFdUserInfoList = newFdMap.get((userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId);

                    if (typeof myPartnerIdFdUserInfoList !== "undefined") {
                        // 自分のオペレーションパートナーIDのユーザー一覧をFD一覧として設定する
                        setFdUserInfoList([...myPartnerIdFdUserInfoList]);
                        setfdOperationPartnerId((userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId);
                        if (myPartnerIdFdUserInfoList.some(myPartnerIdFdUserInfo => {
                            return myPartnerIdFdUserInfo.sub === userInfo.sub
                        })) {
                            // 自分のオペレーションパートナーIDのユーザー一覧の中に自分のユーザー情報が存在する場合、自分をFDとしてデフォルトで表示する
                            setFdUserSub(userInfo?.sub);
                            props.onChangeFD(userInfo.sub);
                        };
                    };
                };
            };
        };
        fetchFdData();
    }, []);

    return (
        <Grid container direction="column" spacing={2} sx={{ padding: 1 }}>
            <Grid item>
                <FormControl variant="standard" className={classes.fdFormControl}>
                    <InputLabel className={classes.select} id="operationPartnerId-select-outlined-label">オペレーションパートナーID</InputLabel>
                    <Select
                        variant="standard"
                        className={classes.fdTextField}
                        labelId="operationPartnerId"
                        onChange={handleChangeOperationPartnerId}
                        defaultValue={fdOperationPartnerId}
                        value={fdOperationPartnerId}
                        id="operationPartnerId"
                        label="オペレーションパートナーID">
                        {fdOperationPartnerIdList.map((fdOperationPartnerId, i) => (
                            <MenuItem key={fdOperationPartnerId} value={fdOperationPartnerId}>{fdOperationPartnerId}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="standard" className={classes.fdFormControl}>
                    <InputLabel className={classes.select} id="fdUserInfo-select-outlined-label">フライトディレクター</InputLabel>
                    <Select
                        variant="standard"
                        className={classes.fdTextField}
                        labelId="fdUserInfo"
                        onChange={handleChangeFD}
                        defaultValue={fdUserSub}
                        value={fdUserSub}
                        id="fdUserInfo"
                        label="フライトディレクター">
                        {fdUserInfoList.map((fdUserInfo, i) => (
                            <MenuItem key={fdUserInfo.sub} value={fdUserInfo.sub}>{fdUserInfo.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}