import { TableCell, TableRow, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { DataPrivilegeData, FunctionPrivilegeData, PrivilegeInfo } from 'adoms-common-lib/build/model/PrivilegeInfomation';
import React from "react";

const useStyles = makeStyles()((theme: Theme) =>
({
    tableCell: {
        border: `1px solid ${theme.palette.divider}`,
    }
}));

type privilegeDetailTableRowProps = {
    masterPrivilege: PrivilegeInfo<FunctionPrivilegeData | DataPrivilegeData>;
    privilegeListPerGroup: PrivilegeInfo<FunctionPrivilegeData | DataPrivilegeData>[][]
}

/**
 * 権限詳細情報テーブル行表示用コンポーネント
 * @param props:参照用プロパティ
 */
export const PrivilegeDetailTableRow: React.FC<privilegeDetailTableRowProps> = (props) => {

    const { classes } = useStyles();
    const privilegeTableCellIcon = (privilegeInfoList: PrivilegeInfo<FunctionPrivilegeData | DataPrivilegeData>[]) => {
        if (privilegeInfoList.findIndex(privilegeInfo => privilegeInfo.id === props.masterPrivilege.id) === -1) {
            return (
                <ClearIcon fontSize="small" color="disabled" />
            )
        } else {
            return (
                <PanoramaFishEyeIcon fontSize="small" color="primary" />
            )
        }
    }

    return (
        <TableRow>
            <TableCell className={classes.tableCell} component="th" scope="row" align="left">
                {props.masterPrivilege.data.displaySubId}
            </TableCell>
            {props.privilegeListPerGroup?.map((privilegeInfoList, i) => (
                <TableCell className={classes.tableCell} component="th" scope="row" align="center" key={i}>
                    {privilegeTableCellIcon(privilegeInfoList)}
                </TableCell>
            ))}
        </TableRow>
    )
}
