import { Typography, Grid, Tooltip, Box } from "@mui/material"
import { ShipInformation } from "adoms-common-lib"
import React from "react"
import { Popup } from 'react-map-gl';
import dayjs from 'dayjs';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExploreIcon from '@mui/icons-material/Explore';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import MessageIcon from '@mui/icons-material/Message';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type AisPopupProps = {
    aisInfo: ShipInformation
    setPortPopupInfo: React.Dispatch<React.SetStateAction<ShipInformation | undefined>>
}

const getAISTimeInfo = (timeStamp: string) => {
    const nowTime = dayjs();
    const aisTime = dayjs(timeStamp);

    let diffMinutes = nowTime.diff(aisTime, 'minute');
    if (Number.isNaN(diffMinutes)) {
        diffMinutes = 0;
    };
    return diffMinutes
};

/**
 * ポート情報を表示するポップアップ
 * @param props 
 * @returns 
 */
export const AisPopup: React.FC<AisPopupProps> = (props) => {
    const text = `クラスA:位置情報を航行中は2～10秒ごと、停泊中は3分ごとに更新します。
                クラスB:位置情報を30秒から3分ごとに更新します。`;
    return (
        <Popup
            longitude={props.aisInfo.longitude}
            latitude={props.aisInfo.latitude}
            anchor="bottom"
            onClose={() => props.setPortPopupInfo(undefined)}
            style={{ maxWidth: '300px', zIndex: 300 }}>
            <Grid container spacing={1} direction={"column"}>
                <Grid item container alignItems="center">
                    <MessageIcon fontSize="small" sx={{ marginRight: "6px" }} />
                    {props.aisInfo.messageType != 18 ?
                        <Typography variant='subtitle2'>
                            クラス: A
                        </Typography> :
                        <Typography variant='subtitle2'>
                            クラス: B
                        </Typography>}
                    <Tooltip title={<Box style={{ whiteSpace: 'pre-line' }}>{text}</Box>} arrow>
                        <InfoOutlinedIcon fontSize="inherit" sx={{ marginLeft: "3px" }} />
                    </Tooltip>
                </Grid>

                <Grid item container>
                    <DirectionsBoatIcon fontSize="small" sx={{ marginRight: "6px" }} />
                    <Grid item direction="column">
                        <Typography variant='subtitle2'>
                            {"船舶ID: " + props.aisInfo.userId}
                        </Typography>
                        {props.aisInfo.statusText ?
                            <Typography variant='subtitle2'>
                                {"船舶状況: " + props.aisInfo.statusText}
                            </Typography> :
                            <Typography variant='subtitle2'>
                                クラスBの為ありません
                            </Typography>}
                    </Grid>
                </Grid>

                <Grid item container>
                    <SpeedIcon fontSize="small" sx={{ marginRight: "6px" }} />
                    {props.aisInfo.speedOverGround !== 102.3 ?
                        <Typography variant='subtitle2'>
                            {"対地速度: " + props.aisInfo.speedOverGround + "kt"}
                        </Typography> :
                        <Typography variant='subtitle2'>
                            対地速度が利用不可能です
                        </Typography>
                    }
                </Grid>

                <Grid item container>
                    <ExploreIcon fontSize="small" sx={{ marginRight: "6px" }} />
                    {props.aisInfo.courseOverGround !== 360 ?
                        <Typography variant='subtitle2'>
                            {"方角: " + props.aisInfo.courseOverGround}
                        </Typography> :
                        <Typography variant='subtitle2'>
                            方角が利用不可能です
                        </Typography>}
                </Grid>

                <Grid item container>
                    <LocationOnIcon fontSize="small" sx={{ marginRight: "6px" }} />
                    <Grid item direction="column">
                        <Typography variant='subtitle2'>
                            {"緯度: " + props.aisInfo.latitude}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {"経度: " + props.aisInfo.longitude}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container>
                    <AccessTimeIcon fontSize="small" sx={{ marginRight: "6px" }} />
                    <Typography variant='subtitle2'>
                        {"受信: " + getAISTimeInfo(props.aisInfo.timeStamp) + " 分前"}
                    </Typography>
                </Grid>
            </Grid>
        </Popup>
    );
};