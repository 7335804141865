import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from "@mui/x-date-pickers/TimeField";
import dayjs, { extend } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from "react";

type ETAAdjustDialogProps = {
    isETAAdjustDialogOpen: boolean
    setETAAdjustDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    takeoffDuration1: duration.Duration
    setTakeoffDuration1: React.Dispatch<React.SetStateAction<duration.Duration>>
    LandingDuration1: duration.Duration
    setLandingDuration1: React.Dispatch<React.SetStateAction<duration.Duration>>
    takeoffDuration2: duration.Duration
    setTakeoffDuration2: React.Dispatch<React.SetStateAction<duration.Duration>>
    LandingDuration2: duration.Duration
    setLandingDuration2: React.Dispatch<React.SetStateAction<duration.Duration>>
    transitDuration: duration.Duration
    setTransitDuration: React.Dispatch<React.SetStateAction<duration.Duration>>
};

/**
 * フライトレポートダイアログ
 * @param props 
 * @returns 
 */
export const ETAAdjustDialog: React.FC<ETAAdjustDialogProps> = (props) => {
    const handleFlightStatusDialogClose = () => {
        props.setETAAdjustDialogOpen(false);
    };

    const handleChangDuration = (takeoffDayJS: dayjs.Dayjs | null, setDuration: React.Dispatch<React.SetStateAction<duration.Duration>>) => {
        extend(duration);
        const takeoffTimeSeconds1 = dayjs.duration({ minutes: Number(takeoffDayJS?.format("mm")), seconds: Number(takeoffDayJS?.format("ss")) });
        setDuration(takeoffTimeSeconds1);
    };

    const displayDuration = (icon: JSX.Element,
        title: string,
        duration: duration.Duration,
        setDuration: React.Dispatch<React.SetStateAction<duration.Duration>>) => {
        return (
            <Grid item container spacing={2} direction={"row"} flexWrap="nowrap" xs>
                <Grid item>
                    {icon}
                </Grid>
                <Grid item container direction={"column"} spacing={1}>
                    <Grid item>
                        <Typography color="textSecondary">{title}</Typography>
                    </Grid>
                    <Grid item container direction={"row"} spacing={1}>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimeField
                                    format="m[分] s[秒]"
                                    defaultValue={dayjs().set("minutes", duration?.get("minutes") ?? 0).set("seconds", duration?.get("seconds") ?? 0)}
                                    onChange={(e) => handleChangDuration(e, setDuration)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dialog
            open={props.isETAAdjustDialogOpen}
            onClose={handleFlightStatusDialogClose}
        >
            <DialogTitle id="dialog-title">
                ETA滞空・作業時間調整
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} direction={"column"}>
                    {displayDuration(<FlightTakeoffIcon sx={{ color: "#797979" }} />,
                        "フライト1離陸地点滞空時間", props.takeoffDuration1, props.setTakeoffDuration1)}
                    {displayDuration(<FlightLandIcon sx={{ color: "#797979" }} />,
                        "フライト1着陸地点滞空時間", props.LandingDuration1, props.setLandingDuration1)}
                    {displayDuration(<BatteryChargingFullIcon sx={{ color: "#797979" }} />,
                        "経由時間", props.transitDuration, props.setTransitDuration)}
                    {displayDuration(<FlightTakeoffIcon sx={{ color: "#797979" }} />,
                        "フライト2離陸地点滞空時間", props.takeoffDuration2, props.setTakeoffDuration2)}
                    {displayDuration(<FlightLandIcon sx={{ color: "#797979" }} />,
                        "フライト2着陸地点滞空時間", props.LandingDuration2, props.setLandingDuration2)}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}