import { Amplify, I18n } from 'aws-amplify';
import { Authenticator, Heading, useTheme } from '@aws-amplify/ui-react';
import { AuthFormFields, translations } from '@aws-amplify/ui';
import { CssBaseline } from '@mui/material';
import { blue, orange } from '@mui/material/colors';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Auth, Geo } from 'aws-amplify';
import { onMessage } from 'firebase/messaging';
import '@aws-amplify/ui-react/styles.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { isIos, PushNotificationUtil } from './common/PushNotificationUtil';
import { UserInfoProvider } from './common/UserContext';
import Forbidden403Page from './pages/error/Forbidden403Page';
import FlightDetailView from "./pages/operation/flight/FlightDetailView";
import FlightListView from "./pages/operation/flight/FlightListView";
import DroneGeoInformationView from './pages/operation/geoInformation/DroneGeoInformationView';
import OrderDetailView from "./pages/operation/order/OrderDetailView";
import OrderListView from "./pages/operation/order/OrderListView";
import PortListView from "./pages/operation/port/PortListView";
import PrivilegeListView from './pages/operation/privilege/PrivilegeListView';
import UserListView from './pages/operation/privilege/UserListView';
import AdminView from './pages/admin/adminView'
import MapURLListView from './pages/operation/geoInformation/MapURLListView';
import FlightOperationView from './pages/operation/flightOperation/FlightOperationView';
import CustomerSiteURLListView from './pages/manageCustomer/CustomerSiteURLListView';
import DroneListView from './pages/operation/drone/DroneListView';

let neutralMapName = process.env.REACT_APP_AWS_NEUTRAL_MAP_NAME as string
let saterllitemapName = process.env.REACT_APP_AWS_SATELLITE_MAP_NAME as string
// 既存のcognitoの設定
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string, // REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          neutralMapName: {
            // REQUIRED - Amazon Location Service Map resource name
            style: ["VectorHereExplore"] // REQUIRED - String representing the style of map resource
          },
          saterlltemapName: {
            style: ["RasterHereExploreSatellite"]
          }
        },
        neutral: neutralMapName, // REQUIRED - Amazon Location Service Map resource name to set as default
        satellite: saterllitemapName
      },
      region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Location Service Region
    },
  }
});

I18n.putVocabularies(translations);
I18n.setLanguage('ja');

/**
* Material UIのテーマを変える
*/
const theme = createTheme({
  palette: {
    primary: blue,
    secondary: orange,
    background: {
      paper: "#fafafa",
      default: "#EFEFEF",
    },
  },
});

const App = (props: any) => {
  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 0`}
            level={4}
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "center"
            }} 
          >
            {process.env.REACT_APP_ENV_NAME ?
              process.env.REACT_APP_ENV_NAME + "\nADOMSサインイン画面" :
              "ADOMSサインイン画面"}
          </Heading>
        );
      },
    }
  }

  const formFields: AuthFormFields = {
    signIn: {
      username: {
        placeholder: 'ユーザ名を入力',
        isRequired: true,
        label: 'サインインID *'
      },
      password: {
        placeholder: 'パスワードを入力',
        isRequired: true,
        label: 'パスワード *'
      },
    },
  }

  useEffect(() => {
    const getToken = async () => {
      await Auth.currentUserPoolUser().then(async (data) => {
        //通知許可があるかどうかを確認する。
        Notification.requestPermission(async function (status) {
          console.log('Notification permission status:', status);
          //もしあればFCMのトークンを取得しAmazon SNSのエンドポイントに紐づけ。
          if (Notification.permission === 'granted') {
            const pushNotificationUtil = PushNotificationUtil.instance;
            await pushNotificationUtil.updateToken().catch(error => {
              console.log(error);
            });

            const messaging = pushNotificationUtil.messaging;
            //フォアグラウンドでプッシュ通知を受信した際の動作の定義
            onMessage(
              messaging,
              (payload) => {
                if (
                  payload.notification != null &&
                  payload.notification.title != null &&
                  payload.notification.body != null
                ) {
                  console.log(payload);
                  //Service Workerが起動済みであることを確認し、プッシュ通知を表示させる
                  navigator.serviceWorker.ready.then(reg => {
                    /*
                      フォアグラウンドとバックグラウンドで
                      プッシュ通知のデータ様式が多少異なるため、
                      バックグラウンドで届く通知に合わせる。
                      プッシュ通知がクリックされると、
                      Service Workerで定義されたaddEventListenerが発火する。
                    */
                    reg.showNotification(payload.notification!!.title!!, {
                      body: payload.notification!!.body,
                      data: {
                        FCM_MSG: payload
                      }
                    });
                  });
                }
              }
            )
          } else {
            //通知許可が取り消された場合、通知を受け取るService Workerが存在する場合は動作をstopさせる
            console.log('not granted notification');
            await PushNotificationUtil.instance.unregisterPushNotificationParameter();
          };
        });
      }).catch(error => {
        console.log(error);
      });
    };
    if (!isIos()) {
      getToken();
    }
  }, []);

  return (
    <Authenticator
      hideSignUp={true}
      formFields={formFields}
      components={components}
    >
      <StyledEngineProvider injectFirst>
        <React.StrictMode>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <UserInfoProvider>
                  <Routes>
                    <Route path='/' element={<OrderListView />}></Route>
                    <Route path='/flightoperationview' element={<FlightOperationView />}></Route>
                    <Route path='/orderlistview' element={<OrderListView />}></Route>
                    <Route path='/orderdetailview' element={<OrderDetailView />}></Route>
                    <Route path='/flightlistview' element={<FlightListView />}></Route>
                    <Route path='/flightdetailview' element={<FlightDetailView />}></Route>
                    <Route path='/portlistview' element={<PortListView />}></Route>
                    <Route path='/dronelistview' element={<DroneListView />}></Route>
                    <Route path='/userlistview' element={<UserListView />}></Route>
                    <Route path='/privilegelistview' element={<PrivilegeListView />}></Route>
                    <Route path='/dronegeoinformationview' element={<DroneGeoInformationView />}></Route>
                    <Route path='/mapurllistview' element={<MapURLListView />}></Route>
                    <Route path='/forbidden403page' element={<Forbidden403Page />}></Route>
                    <Route path='/customerURLListView' element={<CustomerSiteURLListView />}></Route>
                    <Route path='/admin' element={<AdminView />}></Route>
                  </Routes>
                </UserInfoProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </React.StrictMode>
      </StyledEngineProvider >
    </Authenticator >
  );
}
export default App;
