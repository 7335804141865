import AddIcon from '@mui/icons-material/Add';
import AirIcon from '@mui/icons-material/Air';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import FlightIcon from '@mui/icons-material/Flight';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Alert, Box, BoxProps, CircularProgress, CssBaseline, Fab, FormControlLabel, Grid, Paper, styled, Switch, Tab, Tabs, TextField, Toolbar, Typography } from "@mui/material";
import { Display, FlightInfo, FlightStatus, FunctionPrivilege, OrderInfo, Port, ReasonCode, UserInfo, WaypointWeatherGeoJson } from "adoms-common-lib";
import dayjs, { Dayjs, duration, extend } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useInterval from 'use-interval';
import { havePrivilege } from '../../../common/PrivilegeUtil';
import { useUserInfoContext } from '../../../common/UserContext';
import { changeMinutes10MinuteRoundingOff } from '../../../common/weatherUtil';
import { TabPanel } from "../../../components/atoms/TabPanel";
import { FlightCardForFlightOperationView } from "../../../components/molecule/FlightCardForFlightOperationView";
import { DroneGeoInformationGrid } from '../../../components/organisms/DroneGeoInformationGrid';
import { EnvSensorInfoGrid } from '../../../components/organisms/EnvSensorInfoGrid';
import { FlightCreateDialog } from '../../../components/organisms/FlightCreateDialog';
import { FlightInfoGrid } from '../../../components/organisms/FlightInfoGrid';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { OrderInfoGrid } from '../../../components/organisms/OrderInfoGrid';
import { WaypointWindGrid } from '../../../components/organisms/WaypointWindGrid';
import { APIConnector } from "../../../connector/APIConnector";

dayjs.extend(utc);
dayjs.extend(timezone);
extend(duration);

interface MainBoxProps extends BoxProps {
    open?: boolean;
}

const drawerWidth = 260;

const MainBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open',
})<MainBoxProps>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    height: "100vh",
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

/**
 * フライトオペレーション
 */
export default function FlightOperationView() {

    const location = useLocation();
    const userInfoContext = useUserInfoContext();
    const flightIdRef = useRef<string | null>(null);
    const [isOpen, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [orderErrorMessage, setOrderErrorMessage] = React.useState<string | undefined>(undefined);
    const [todayOperationFlightInfoList, setTodayOperationFlightInfoList] = React.useState<FlightInfo[]>([]);
    const [todayNotOperationFlightInfoList, setTodayNotOperationFlightInfoList] = React.useState<FlightInfo[]>([]);
    const [selectedFlightInfo, setSelectedFlightInfo] = React.useState<FlightInfo | undefined>(undefined);
    const [orderInfo, setOrderInfo] = React.useState<OrderInfo>();
    const [tabValue, setTabValue] = React.useState(0);
    const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(userInfoContext.userInfo);
    const [userErrorMessage, setUserErrorMessage] = React.useState<string | undefined>(undefined);
    const [displayFlightListCircularProgress, setDisplayFlightListCircularProgress] = React.useState(true);
    const [displayOrderCircularProgress, setDisplayOrderCircularProgress] = React.useState(false);
    const [isSavedFlightInfo, setSavedFlightInfo] = React.useState(false);
    const [isSavedOrderInfo, setSavedOrderInfo] = React.useState(false);
    const [flightIdTiedToDisplayOrder, setFlightIdTiedToDisplayOrder] = React.useState<string>();
    const [isFlightCreateDialogOpen, setFlightCreateDialogOpen] = React.useState(false);
    const [allPortList, setAllPortList] = React.useState<Port[]>([]);
    const [portErrorMessage, setPortErrorMessage] = React.useState<string | undefined>(undefined);
    const [newFlightId, setNewFlightId] = React.useState<string | undefined>(location.state ? location.state.id as string : undefined);
    const [isDisplayOperationFlight, setDisplayOperationFlight] = React.useState(true);
    const [updateTime, setUpdateTime] = React.useState<string>(Display.getNowJSTTimeToString());
    const [nextUpdateWeatherInfoDayJs, setNextUpdateWeatherInfoDayJs] = React.useState<Dayjs>();
    const [isUpdateWeatherInfo, setUpdateWeatherInfo] = React.useState(false);
    const [waypointWeatherGeoJson, setWaypointWeatherGeoJson] = React.useState<WaypointWeatherGeoJson | undefined>();
    const [waypointWindErrorMessage, setWaypointWindErrorMessage] = React.useState<string | undefined>(undefined);
    const [displayflightIdByWaypointWind, setDisplayflightIdByWaypointWind] = React.useState<string>();
    const [selectedFlightEtd, setSelectedFlightEtd] = React.useState<string>();
    const [isDisplayWaypointWindLoading, setDisplayWaypointWindLoading] = React.useState(false);
    const [flightDate, setFlightDate] = React.useState(Display.getNowDateWithString());
    const [lastFocusTime, setLastFocusTime] = React.useState<Dayjs | undefined>(undefined);
    const [updateMessage, setUpdateMessage] = React.useState<string | undefined>(undefined);
    const [isUpdateStreamingData, setIsUpdateStreamingData] = React.useState(true);
    const [routeDataMap, setRouteDataMap] = React.useState<Map<string, FeatureCollection<Geometry, GeoJsonProperties> | undefined>>();
    const [routeErrorMessage, setRouteErrorMessage] = React.useState<string | undefined>(undefined);
    const [takeoffDuration1, setTakeoffDuration1] = React.useState<Duration>(dayjs.duration({ minutes: 1, seconds: 0 }));
    const [landingDuration1, setLandingDuration1] = React.useState<Duration>(dayjs.duration({ minutes: 1, seconds: 0 }));
    const [takeoffDuration2, setTakeoffDuration2] = React.useState<Duration>(dayjs.duration({ minutes: 1, seconds: 0 }));
    const [landingDuration2, setLandingDuration2] = React.useState<Duration>(dayjs.duration({ minutes: 1, seconds: 0 }));
    const [transitDuration, setTransitDuration] = React.useState<Duration>(dayjs.duration({ minutes: 10, seconds: 0 }));
    const [isFilter, setFilter] = React.useState(false);

    /**
     * 画面が表示された時、またはフライト情報を更新した場合に
     * フライトリストを取得する
     */
    useEffect(() => {
        setDisplayFlightListCircularProgress(true);
        setDisplayOrderCircularProgress(true);
        setSavedFlightInfo(false);
        fetchTodayFlightListData();
    }, [isSavedFlightInfo, flightDate]);

    /**
     * 5秒間隔でフライトリストを取得、かつ天候情報を取得するか判定する
     */
    useInterval(() => {
        if (!document.hasFocus()) {
            let lastFocusTimeLocal = lastFocusTime?.clone();
            if (lastFocusTime == null) {
                lastFocusTimeLocal = dayjs();
                setLastFocusTime(lastFocusTimeLocal);
            }
            if (dayjs().diff(lastFocusTimeLocal, 'second') <= 300) {
                fetchTodayFlightListData();
                checkUpdateWeatherInfo();
            } else {
                setIsUpdateStreamingData(false);
                setUpdateMessage("更新停止中")
            }
        } else {
            setIsUpdateStreamingData(true);
            setUpdateMessage("自動更新中")
            setLastFocusTime(undefined);
            fetchTodayFlightListData();
            checkUpdateWeatherInfo();
        }
    }, 3000);

    /**
     * 天候情報を更新するかどうか判定する
     */
    const checkUpdateWeatherInfo = () => {
        // 現在時刻
        const nowDayJs = dayjs();
        if (!nextUpdateWeatherInfoDayJs || nowDayJs.isAfter(nextUpdateWeatherInfoDayJs)) {
            // 現在時刻が天候情報を取得する時間を過ぎている場合
            setUpdateWeatherInfo(true);
        };
    };

    /**
     * 画面がアクティブの場合、本日のフライトリストを取得する
     */
    const fetchTodayFlightListData = async () => {
        let apiConnector: APIConnector = APIConnector.instance;
        await apiConnector.getFlightListByFlightDate(flightDate)
            .then(async todayFlightInfoListParam => {
                console.log("フライト一覧取得");
                setUpdateTime(Display.getNowJSTTimeToString());
                setErrorMessage(undefined);

                const todayOperationFlightInfoListParam = todayFlightInfoListParam.filter((flightInfo => {
                    return flightInfo.status !== FlightStatus.Canceled
                        && flightInfo.status !== FlightStatus.Completed
                }));
                setTodayOperationFlightInfoList(todayOperationFlightInfoListParam);

                setTodayNotOperationFlightInfoList(todayFlightInfoListParam.filter((flightInfo => {
                    return flightInfo.status === FlightStatus.Canceled
                        || flightInfo.status === FlightStatus.Completed
                })));

                if (todayFlightInfoListParam.length > 0) {
                    if (newFlightId) {
                        // 新しくフライト情報を作成した場合
                        const flightInfo = todayFlightInfoListParam.find(flightInfo =>
                            flightInfo.id === newFlightId);
                        if (flightInfo) {
                            setSelectedFlightInfo(flightInfo);
                            flightIdRef.current = flightInfo.id;
                        };
                    } else if (selectedFlightInfo) {
                        // フライトが既に選択されている場合
                        const flightInfo = todayFlightInfoListParam.find(flightInfo =>
                            flightInfo.id === flightIdRef.current);
                        if (flightInfo?.status === FlightStatus.Canceled
                            || flightInfo?.status === FlightStatus.Completed) {
                            //フライトがオペレーション以外の場合は、オペレーションフライト表示フラグをfalseにする
                            setDisplayOperationFlight(false);
                            if (isDisplayOperationFlight) {
                                // フライトがオペレーション中からオペレーション以外に変わった時はスクロールさせる
                                setNewFlightId(flightInfo.id);
                            };
                        } else {
                            //フライトがオペレーション中の場合は、オペレーションフライト表示フラグをtrueにする
                            setDisplayOperationFlight(true);
                            if (!isDisplayOperationFlight) {
                                // フライトがオペレーション以外からオペレーション中に変わった時はスクロールさせる
                                setNewFlightId(flightInfo?.id);
                            };
                        };
                        setSelectedFlightInfo(flightInfo);
                    } else {
                        if (isDisplayOperationFlight && todayOperationFlightInfoListParam.length > 0) {
                            const flightInfo = todayOperationFlightInfoListParam[0];
                            setSelectedFlightInfo(flightInfo);
                            flightIdRef.current = flightInfo.id;
                        } else if (!isDisplayOperationFlight && todayNotOperationFlightInfoList.length > 0) {
                            const flightInfo = todayNotOperationFlightInfoList[0];
                            setSelectedFlightInfo(flightInfo);
                            flightIdRef.current = flightInfo.id;
                        };
                    };
                } else {
                    setSelectedFlightInfo(undefined);
                };
                setDisplayFlightListCircularProgress(false);
            }).catch(error => {
                console.log("error:" + error.message);
                setErrorMessage("フライト情報を取得できませんでした。");
                setDisplayFlightListCircularProgress(false);
            });
    };

    /**
     * 選択されたフライトまでスクロールする
     */
    const scrollToSelectedFlight = React.useCallback((node: HTMLDivElement | null, flightInfo: FlightInfo) => {
        if (node && flightInfo.id === newFlightId) {
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
            setNewFlightId(undefined);
        };
    }, [selectedFlightInfo]);

    /**
     * 選択されたフライトに紐づく配送情報リストを取得する
     */
    useEffect(() => {
        const fetchOrderData = async () => {
            let apiConnector: APIConnector = APIConnector.instance;
            try {
                if (selectedFlightInfo) {
                    if (flightIdTiedToDisplayOrder !== selectedFlightInfo.id) {
                        // 現在表示している配送情報に紐づくフライトIDと現在選択されているフライトIDが同じではない場合、ローディングマークを表示する
                        setDisplayOrderCircularProgress(true);
                    };
                    setFlightIdTiedToDisplayOrder(selectedFlightInfo.id);
                    setSavedOrderInfo(false);
                    setOrderErrorMessage(undefined);
                    const orderIdTiedToFlightIdParam = await apiConnector.getOrderIdTiedToFlightId(selectedFlightInfo);
                    if (orderIdTiedToFlightIdParam.length > 0) {
                        const selectedOrderInfo = await apiConnector.getOrderForOperation(orderIdTiedToFlightIdParam[0].orderId);
                        console.log("配送情報取得");
                        setOrderInfo(selectedOrderInfo);
                    } else {
                        setOrderInfo(undefined);
                    }
                    setDisplayOrderCircularProgress(false);
                } else {
                    setDisplayOrderCircularProgress(false);
                    setOrderInfo(undefined);
                }
            } catch (error) {
                setDisplayOrderCircularProgress(false);
                setOrderErrorMessage("配送情報が取得できませんでした。");
            };
        };
        fetchOrderData();
    }, [selectedFlightInfo, isSavedOrderInfo]);

    /**
     * ユーザー情報を取得する
     */
    useEffect(() => {
        setUserInfo(userInfoContext.userInfo);
        if (userInfoContext.isError) {
            setUserErrorMessage("ユーザー情報を取得できませんでした。");
        } else {
            setUserErrorMessage(undefined)
        }
    }, [userInfoContext]);


    /**
     * 初回のみ全てのポート情報を取得する
     */
    useEffect(() => {
        const fetchAllPortList = async () => {
            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.getPortListForOperation().then(async (portList: Port[]) => {
                setPortErrorMessage(undefined);
                setAllPortList(portList);
            }).catch((error) => {
                console.log(error);
                setPortErrorMessage("ポート情報を取得できませんでした。");
            });
        };
        fetchAllPortList();
    }, []);

    useEffect(() => {
        const getWaypointWeatherGeoJson = async () => {
            if (selectedFlightInfo && (displayflightIdByWaypointWind !== selectedFlightInfo.id
                || selectedFlightInfo.etd !== selectedFlightEtd)) {
                setDisplayflightIdByWaypointWind(undefined);
                setTakeoffDuration1(dayjs.duration({ minutes: 1, seconds: 0 }));
                setLandingDuration1(dayjs.duration({ minutes: 1, seconds: 0 }));
                setTakeoffDuration2(dayjs.duration({ minutes: 1, seconds: 0 }));
                setLandingDuration2(dayjs.duration({ minutes: 1, seconds: 0 }));
                setTransitDuration(dayjs.duration({ minutes: 10, seconds: 0 }));
                let apiConnector: APIConnector = APIConnector.instance;
                let etd10Minutes = changeMinutes10MinuteRoundingOff(
                    dayjs(selectedFlightInfo.flightDate + " " + selectedFlightInfo.etd));
                setDisplayWaypointWindLoading(true);
                setDisplayflightIdByWaypointWind(selectedFlightInfo!.id);
                await apiConnector.getWeatherGeoJsonByWaypoint(
                    selectedFlightInfo.flightDate,
                    etd10Minutes.format("HH:mm"),
                    "2000",
                    60,
                    130,
                    selectedFlightInfo.businessPartnerId,
                    selectedFlightInfo.departure.id,
                    selectedFlightInfo.arrival.id
                ).then(waypointWeatherGeoJson => {
                    setSelectedFlightEtd(selectedFlightInfo?.etd);
                    setWaypointWindErrorMessage(undefined);
                    setDisplayWaypointWindLoading(false);
                    setWaypointWeatherGeoJson(waypointWeatherGeoJson);
                    console.log(waypointWeatherGeoJson);
                }).catch(error => {
                    console.log(error);
                    setDisplayWaypointWindLoading(false);
                    if (error.response
                        && error.response.data.reasonCode == "WWP0006") {
                        setWaypointWindErrorMessage("天候情報を取得できません。24分以降にアクセスしてください。");
                    } else {
                        setWaypointWindErrorMessage("気象情報が取得できませんでした");
                    }
                });
            };
        };
        getWaypointWeatherGeoJson();
    }, [selectedFlightInfo]);

    useEffect(() => {
        const dataload = async () => {
            if (selectedFlightInfo && !routeDataMap?.has(selectedFlightInfo.id)) {
                // 表示させるルートを取得する
                let apiConnector: APIConnector = APIConnector.instance;
                await apiConnector.getRoute(selectedFlightInfo?.businessPartnerId,
                    selectedFlightInfo?.departure.id,
                    selectedFlightInfo?.arrival.id
                ).then((featureCollection) => {
                    //KMLローダーで読み込んだデータはGeoJSON形式に変換されている
                    console.log(featureCollection);
                    setRouteErrorMessage(undefined);
                    const routeDataMapParam = new Map(routeDataMap);
                    routeDataMapParam.set(selectedFlightInfo!.id, featureCollection);
                    //読み込んだデータを保存
                    setRouteDataMap(routeDataMapParam);
                }).catch(error => {
                    if (error.response
                        && error.response.data.reasonCode == ReasonCode.GRO0003) {
                        const routeDataMapParam = new Map(routeDataMap);
                        routeDataMapParam.set(selectedFlightInfo!.id, undefined);
                        setRouteDataMap(routeDataMapParam);
                    } else {
                        setRouteErrorMessage("ルートが取得できませんでした")
                    };
                });
            };
        };
        dataload();
    }, [selectedFlightInfo]);

    /**
    * tabのidをセットする
    */
    const changeTabId = (index: any) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    const displayFlightList = (flightInfoList: FlightInfo[]) => {
        return (

            flightInfoList.length !== 0 ?
                flightInfoList.map((flightInfo, i) => (
                    <Grid item xs={12} key={i}
                        sx={{ paddingTop: i === 0 ? 2 : undefined }}
                        ref={(node) =>
                            scrollToSelectedFlight(node, flightInfo)}>
                        <FlightCardForFlightOperationView
                            flightInfo={flightInfo}
                            selectedFlightInfo={selectedFlightInfo}
                            setSelectedFlightInfo={setSelectedFlightInfo}
                            flightIdRef={flightIdRef} />
                    </Grid>
                ))
                :
                displayFlightListCircularProgress ?
                    <Grid item xs={12} textAlign={"center"}>
                        <CircularProgress />
                    </Grid> :
                    <Grid item xs={12} style={{ paddingTop: 3 }}>
                        <Paper sx={{ padding: 2 }}>
                            {errorMessage ?
                                <Alert severity="error" >
                                    <Typography >{errorMessage}</Typography>
                                </Alert>
                                :
                                <Alert severity="info" >フライトが設定されていません</Alert>
                            }
                        </Paper>
                    </Grid>
        )
    };

    const changeOperationFlightFlg = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayOperationFlight(event.target.checked);
        if (event.target.checked) {
            // オペレーション中のフライトを表示する場合
            if (todayOperationFlightInfoList.length > 0) {
                const flightInfo = todayOperationFlightInfoList[0]
                setSelectedFlightInfo(flightInfo);
                flightIdRef.current = flightInfo.id;
            } else {
                setSelectedFlightInfo(undefined);
            };
        } else {
            // オペレーション中ではないフライトを表示する場合
            if (todayNotOperationFlightInfoList.length > 0) {
                const flightInfo = todayNotOperationFlightInfoList[0];
                setSelectedFlightInfo(flightInfo);
                flightIdRef.current = flightInfo.id;
            } else {
                setSelectedFlightInfo(undefined);
            };
        };
    };

    const changeFlightDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFlightDate(e.target.value);
        setSelectedFlightInfo(undefined);
        setUpdateWeatherInfo(true);
        setTodayOperationFlightInfoList([]);
        setTodayNotOperationFlightInfoList([]);
        setDisplayOperationFlight(true);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={() => setOpen(true)}
                onChangeDrawerClose={() => setOpen(false)}
                open={isOpen}
                title="Operation"
                updateTime={updateTime}
                updateMessage={updateMessage}
            />
            {/** コンテンツ部分 */}
            <MainBox
                open={isOpen}
                component="main"
            >
                <Toolbar />
                {userErrorMessage ?
                    <Paper sx={{ padding: 2, marginBottom: 3 }}>
                        <Alert severity="error" >{userErrorMessage}</Alert>
                    </Paper> : undefined}
                <Grid container spacing={3} >
                    <Grid item xs={12} md={3} lg={3} xl={3}
                        spacing={3}
                        overflow="auto"
                        sx={{
                            height: `calc(100vh - 88px)`
                        }}
                        position="relative"
                    >
                        <Grid container spacing={2}>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    onChange={changeFlightDate}
                                    id="date"
                                    label="フライト日"
                                    type="date"
                                    value={flightDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={RegExp(/\d{4}-\d{2}-\d{2}/).test(flightDate) ? '' : '正しい日付を入力してください'} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch checked={isDisplayOperationFlight}
                                            onChange={(e) => changeOperationFlightFlg(e)}
                                            name="Filght in operation" />
                                    }
                                    label={<Typography variant='subtitle2'>オペレーション中のフライト</Typography>}
                                />
                            </Grid>
                            {
                                isDisplayOperationFlight ?
                                    displayFlightList(todayOperationFlightInfoList)
                                    :
                                    displayFlightList(todayNotOperationFlightInfoList)
                            }
                        </Grid>
                        {havePrivilege(FunctionPrivilege.PrivilegeFunctionCreateFlight, userInfo) ?
                            <Grid item position="sticky" bottom={8} textAlign={"right"} margin={1}>
                                <Fab color='primary' onClick={() => setFlightCreateDialogOpen(true)}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                            : undefined}
                    </Grid>
                    <Grid item xs={12} md={4.6} lg={4.6} xl={4.6}
                        sx={{
                            minHeight: `calc(100vh - 88px)`
                        }}>
                        <Paper sx={{
                            height: "100%"
                        }}>
                            <Tabs
                                value={tabValue}
                                onChange={(event, newValue) => {
                                    setTabValue(newValue);
                                }}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                sx={{ boxShadow: 1 }}
                            >
                                <Tab icon={<DeviceThermostatIcon />}
                                    label="Sensor"
                                    {...changeTabId(0)} />
                                {/* <Tab icon={<CheckIcon />}
                                    label="Check"
                                    {...changeTabId(1)} /> */}
                                <Tab icon={<AirIcon />}
                                    label="Wind"
                                    {...changeTabId(1)} />
                                <Tab icon={<FlightIcon
                                    sx={{
                                        transform: "rotate(90deg)"
                                    }} />}
                                    label="Flight" {...changeTabId(2)} />
                                <Tab icon={<ShoppingCartOutlinedIcon />}
                                    label="Order"
                                    {...changeTabId(3)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <EnvSensorInfoGrid
                                    selectedFlightInfo={selectedFlightInfo} />
                            </TabPanel>
                            {/* <TabPanel value={tabValue} index={1}>
                                <FlightCheckListGrid
                                    selectedFlightInfo={selectedFlightInfo} />
                            </TabPanel> */}
                            <TabPanel value={tabValue} index={1}>
                                <WaypointWindGrid
                                    selectedFlightInfo={selectedFlightInfo}
                                    waypointWeatherGeoJson={waypointWeatherGeoJson}
                                    setWaypointWeatherGeoJson={setWaypointWeatherGeoJson}
                                    waypointWindErrorMessage={waypointWindErrorMessage}
                                    setWaypointWindErrorMessage={setWaypointWindErrorMessage}
                                    isDisplayWaypointWindLoading={isDisplayWaypointWindLoading}
                                    setDisplayWaypointWindLoading={setDisplayWaypointWindLoading}
                                    selectedFlightEtd={selectedFlightEtd}
                                    setSelectedFlightEtd={setSelectedFlightEtd}
                                    routeDataMap={routeDataMap}
                                    routeErrorMessage={routeErrorMessage}
                                    takeoffDuration1={takeoffDuration1}
                                    setTakeoffDuration1={setTakeoffDuration1}
                                    landingDuration1={landingDuration1}
                                    setLandingDuration1={setLandingDuration1}
                                    takeoffDuration2={takeoffDuration2}
                                    setTakeoffDuration2={setTakeoffDuration2}
                                    landingDuration2={landingDuration2}
                                    setLandingDuration2={setLandingDuration2}
                                    transitDuration={transitDuration}
                                    setTransitDuration={setTransitDuration}
                                    isFilter={isFilter}
                                    setFilter={setFilter}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <FlightInfoGrid
                                    selectedFlightInfo={selectedFlightInfo}
                                    userInfo={userInfo}
                                    setSavedFlightInfo={setSavedFlightInfo}
                                    orderInfo={orderInfo}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                {displayOrderCircularProgress ?
                                    <Grid textAlign={"center"}>
                                        <CircularProgress />
                                    </Grid >
                                    :
                                    selectedFlightInfo ?
                                        <OrderInfoGrid
                                            orderInfo={orderInfo}
                                            setSavedOrderInfo={setSavedOrderInfo} />
                                        :
                                        <Grid xs={12}>
                                            {orderErrorMessage ?
                                                <Alert severity="error" > {orderErrorMessage} </Alert>
                                                :
                                                <Alert severity="info" >フライトを選択してください</Alert>
                                            }
                                        </Grid>
                                }
                            </TabPanel>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4.4} lg={4.4} xl={4.4}
                        spacing={3}
                        direction={"column"}
                        sx={{
                            minHeight: `calc(100vh - 88px)`
                        }}
                    >
                        <Paper sx={{ height: "100%", minHeight: "200px" }}>
                            <DroneGeoInformationGrid
                                selectedFlightInfo={selectedFlightInfo}
                                allPortList={allPortList}
                                portErrorMessage={portErrorMessage}
                                setNextUpdateWeatherInfoDayJs={setNextUpdateWeatherInfoDayJs}
                                isUpdateWeatherInfo={isUpdateWeatherInfo}
                                setUpdateWeatherInfo={setUpdateWeatherInfo}
                                isUpdateStreamingData={isUpdateStreamingData}
                                routeDataMap={routeDataMap}
                                routeErrorMessage={routeErrorMessage}
                                isFilter={isFilter}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </MainBox >
            <FlightCreateDialog
                isFlightCreateDialogOpen={isFlightCreateDialogOpen}
                setFlightCreateDialogOpen={setFlightCreateDialogOpen}
                userInfo={userInfo}
                allPortList={allPortList}
                setNewFlightId={setNewFlightId}
                setSavedFlightInfo={setSavedFlightInfo} />
        </Box >
    )
}