export class IncrementalSearchUtil {

    public incrementalSearch<T>(searchSources: T[], keyword: string): number[] {
        const indexList: number[] = [];

        searchSources.map((searchSource: T, index: number) => {
            if (JSON.stringify(searchSource).includes(keyword)) {
                indexList.push(index);
            };
        });
        return indexList;
    };
};