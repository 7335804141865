import React, { useEffect, useState } from "react";
import { DialogTitle, DialogContent, Grid, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Divider, Alert, Tooltip, Chip, Checkbox, ListItemText, Paper } from "@mui/material";
import { BusinessPartnerInfo, DroneInfo, OperationPartnerInfo, PartnerKind, UserInfo } from "adoms-common-lib";
import { useUserInfoContext } from "../../common/UserContext";

// propsの型を定義
type DroneBasicInformationContentporps = {
    droneData: DroneInfo;
    allDroneList: DroneInfo[] | undefined;
    setDroneData: React.Dispatch<React.SetStateAction<DroneInfo>>;
    setIsNextDisable: React.Dispatch<React.SetStateAction<boolean>>;
};

// ポート情報ダイアログ
const DroneBasicInformationContent: React.FC<DroneBasicInformationContentporps> = (props) => {
    const userInfoContext = useUserInfoContext();
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(userInfoContext.userInfo);
    const [allBusinessPartnerInfoList, setAllBusinessPartnerInfoList] = useState<BusinessPartnerInfo[]>([]);
    const [droneBusinessPartnerIdList, setDroneBusinessPartnerIdList] = useState<string[]>([]);
    const [droneName, setDroneName] = useState<string>(props.droneData.label);
    const [droneOwner, setDroneOwner] = useState<string>(props.droneData.owner);
    const [droneDesignManufacturer, setDroneDesignManufacturer] = useState<string>(props.droneData.designManufacturer);
    const [userErrorMessage, setUserErrorMessage] = useState<string | undefined>();

    // ユーザー情報を取得する
    useEffect(() => {
        setUserInfo(userInfoContext.userInfo);
        if (userInfoContext.isError) {
            setUserErrorMessage("ユーザー情報を取得できませんでした。");
        } else {
            setUserErrorMessage(undefined);
        };
    }, [userInfoContext]);

    // ユーザー情報からビスネスパートナーリストの取得
    const getVisibleBusinessPartnerInfoList = (userInfo?: UserInfo): BusinessPartnerInfo[] => {
        let visibleBusinessPartnerInfoList = new Array<BusinessPartnerInfo>();
        const userPartnerInfo = userInfo?.userPartnerInfo;

        if (userPartnerInfo?.partnerKind === PartnerKind.Business) {
            visibleBusinessPartnerInfoList.push(
                userPartnerInfo.partnerInfo as BusinessPartnerInfo
            );
        } else if (userPartnerInfo?.partnerKind === PartnerKind.Operation) {
            for (let visibleBusinessPartnerInfo of (
                userPartnerInfo.partnerInfo as OperationPartnerInfo
            ).visibleBusinessPartnerInfoList) {
                visibleBusinessPartnerInfoList.push(visibleBusinessPartnerInfo);
            }
        }
        return visibleBusinessPartnerInfoList;
    };

    // Chipの削除を押した時
    const chipDelete = (businessPartnerInfoParam: string) => {
        const businessPartnerInfoListParam = [
            ...droneBusinessPartnerIdList.filter((droneBusinessPartnerInfo) => droneBusinessPartnerInfo !== businessPartnerInfoParam),
        ];
        setDroneBusinessPartnerIdList(businessPartnerInfoListParam);
        setDroneBusinessPartner(businessPartnerInfoListParam);
    };

    // 入力されたビジネスパートナーの情報取得
    const setDroneBusinessPartner = (selected: string[]) => {
        const newBusinessPartnerInfoList: BusinessPartnerInfo[] = allBusinessPartnerInfoList.filter((businessPartnerInfo) =>
            selected.includes(businessPartnerInfo.businessPartnerId)
        );
        props.setDroneData({
            ...props.droneData,
            businessPartnerInfoList: newBusinessPartnerInfoList
        });
    };

    // ビジネスパートナーリストを取得
    useEffect(() => {
        const fetchInitialData = async () => {
            const visibleBusinessPartnerInfoList =
                getVisibleBusinessPartnerInfoList(userInfo);
            setAllBusinessPartnerInfoList(visibleBusinessPartnerInfoList);
        };
        fetchInitialData();
    }, [userInfo]);

    // 全て入力されているかのチェック
    useEffect(() => {
        const setDefaultValue = () => {
            let businessPartnerIdList: string[] = [];
            props.droneData.businessPartnerInfoList.map((businessPartnerInfo) => {
                businessPartnerIdList.push(businessPartnerInfo.businessPartnerId);
            });
            setDroneBusinessPartnerIdList(businessPartnerIdList);
            setDroneName(props.droneData.label);
            setDroneOwner(props.droneData.owner);
            setDroneDesignManufacturer(props.droneData.designManufacturer);
        };
        setDefaultValue();

        if (props.droneData.businessPartnerInfoList.length !== 0 &&
            props.droneData.label !== "" &&
            props.droneData.owner !== "" &&
            props.droneData.designManufacturer !== ""
        ) {
            props.setIsNextDisable(false);
        } else {
            props.setIsNextDisable(true);
        }
    }, [props.droneData]);

    return (
        <>
            {/* ダイアログタイトル */}
            <DialogTitle id="responsive-dialog-title">
                機体基本情報
                <Divider />
            </DialogTitle>

            {/* ダイアログコンテンツ */}
            <DialogContent>
                <Grid container spacing={3} direction="column">

                    {/* ユーザーID取得エラー時 */}
                    {
                        userErrorMessage ?
                            <Grid item>
                                <Paper elevation={1}>
                                    <Alert severity="error">{userErrorMessage}</Alert>
                                </Paper>
                            </Grid>
                            : null
                    }

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            ビジネスパートナー
                        </Typography>
                    </Grid>

                    {/* ビジネスパートナー */}
                    <Grid item style={{ paddingTop: "0px" }}>
                        <FormControl variant="filled" size='small' sx={{ width: "218px" }}>
                            <InputLabel>ビジネスパートナー</InputLabel>
                            <Select
                                multiple
                                value={droneBusinessPartnerIdList}
                                onChange={(e) => {
                                    const selected = e.target.value as string[];
                                    setDroneBusinessPartnerIdList(selected);
                                    setDroneBusinessPartner(selected);
                                }}
                                renderValue={(selected) => (
                                    <div style={{ display: "flex", flexWrap: "wrap", maxHeight: "100px", overflow: "auto" }}>
                                        {selected.map((value, index: number) => (
                                            <Tooltip key={index} title={allBusinessPartnerInfoList.find((businessPartnerInfo) => businessPartnerInfo.businessPartnerId === value)?.businessPartnerName} arrow>
                                                <Chip
                                                    sx={{ margin: 0.5 }}
                                                    size="small"
                                                    key={index}
                                                    label={allBusinessPartnerInfoList.find((businessPartnerInfo) => businessPartnerInfo.businessPartnerId === value)?.businessPartnerName}
                                                    onDelete={(_) => {
                                                        chipDelete(value);
                                                    }}
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                    style={{ flex: "0 0 auto" }}
                                                />
                                            </Tooltip>
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {allBusinessPartnerInfoList.map((businessPartnerInfo) => (
                                    <MenuItem
                                        key={businessPartnerInfo.sortNumber}
                                        value={businessPartnerInfo.businessPartnerId}
                                    >
                                        <Checkbox
                                            checked={
                                                droneBusinessPartnerIdList?.findIndex((droneBusinessPartnerInfo) =>
                                                    droneBusinessPartnerInfo === businessPartnerInfo.businessPartnerId
                                                ) !== -1
                                            }
                                        />
                                        <Tooltip title={businessPartnerInfo.businessPartnerName} arrow>
                                            <ListItemText primary={businessPartnerInfo.businessPartnerName} />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            機体情報
                        </Typography>
                    </Grid>

                    {/** 機体名 */}
                    <Grid item xs={12} style={{ paddingTop: "0px" }}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="機体名"
                            value={droneName}
                            onChange={(e) => {
                                setDroneName(e.target.value);
                                props.setDroneData({
                                    ...props.droneData,
                                    label: e.target.value,
                                });
                            }}
                        />
                    </Grid>

                    {/** 機体保有者 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="機体保有者"
                            value={droneOwner}
                            onChange={(e) => {
                                setDroneOwner(e.target.value);
                                props.setDroneData({
                                    ...props.droneData,
                                    owner: e.target.value,
                                });
                            }}
                        />
                    </Grid>

                    {/** 製造元 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="製造元"
                            value={droneDesignManufacturer}
                            onChange={(e) => {
                                setDroneDesignManufacturer(e.target.value);
                                props.setDroneData({
                                    ...props.droneData,
                                    designManufacturer: e.target.value
                                })
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent >
        </>
    );
};

export default DroneBasicInformationContent;