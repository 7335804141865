import { BusinessPartnerInfo, DroneInfo, OperationPartnerInfo, PartnerKind, Port, Purpose, Specific, UserInfo } from "adoms-common-lib";
import axios from "axios";
import { APIConnector } from "../connector/APIConnector";

export type InputValueType = {
    flightDate: string,
    std: string,
    sta: string,
    arrivalPortId: string,
    departurePortId: string,
    fdUserSub: string,
    droneRegistrationId: string,
    payload: number,
    businessPartnerId: string,
    orderId: string,
    purpose: Purpose,
    specificList?: Specific[]
}

export const getNowTimeForSTD = (): string => {
    let systemTime = new Date();
    let nowHour = ("0" + systemTime.getHours()).slice(-2);
    let nowMinute = ("0" + systemTime.getMinutes()).slice(-2);
    return `${nowHour}:${nowMinute}`;
};

export const getVisibleBusinessPartnerInfoList = (userInfo?: UserInfo): BusinessPartnerInfo[] => {
    let visibleBusinessPartnerInfoList = new Array<BusinessPartnerInfo>();
    const userPartnerInfo = userInfo?.userPartnerInfo;
    if (userPartnerInfo?.partnerKind === PartnerKind.Business) {
        visibleBusinessPartnerInfoList.push(userPartnerInfo.partnerInfo as BusinessPartnerInfo);
    } else if (userPartnerInfo?.partnerKind === PartnerKind.Operation) {
        for (let visibleBusinessPartnerInfo of (userPartnerInfo.partnerInfo as OperationPartnerInfo).visibleBusinessPartnerInfoList) {
            visibleBusinessPartnerInfoList.push(visibleBusinessPartnerInfo);
        }
    };
    return visibleBusinessPartnerInfoList;
};

export const getDeparturePortList = (
    allPortList: Port[],
    businessPartnerId: string,
    setDeparturePortList: React.Dispatch<React.SetStateAction<Port[]>>) => {
    //パートナー情報に一致する出発ポート情報を設定
    let departurePortList = allPortList.filter(port => {
        return port.businessPartnerId === businessPartnerId;
    });
    setDeparturePortList(departurePortList);
    return departurePortList;
};

export const getArrivalPortList = (
    departurePort: Port,
    allPortList: Port[],
    businessPartnerId: string,
    setArrivalPortList: React.Dispatch<React.SetStateAction<Port[]>>) => {

    //パートナー情報に一致する到着ポート情報を設定
    let arrivalPortList = allPortList.filter(port => {
        return port.businessPartnerId === businessPartnerId &&
            departurePort.destinationPortList.some(destinationPort => {
                return destinationPort === port.id
            });
    });
    setArrivalPortList(arrivalPortList);
    return arrivalPortList;
};

export const getDroneInfoList = async (
    businessPartnerId: string,
    allDroneInfoList: DroneInfo[],
    setAllDroneInfoList: React.Dispatch<React.SetStateAction<DroneInfo[]>>,
    setDisplayDroneInfoList: React.Dispatch<React.SetStateAction<DroneInfo[]>>,) => {

    let allDroneInfoListParam: DroneInfo[]
    if (allDroneInfoList.length === 0) {
        let apiConnector: APIConnector = APIConnector.instance;
        allDroneInfoListParam = await apiConnector.getDroneList();
        setAllDroneInfoList(allDroneInfoListParam);
    } else {
        allDroneInfoListParam = [...allDroneInfoList];
    };

    let droneInfoList: DroneInfo[] = [];
    for (let droneInfo of allDroneInfoListParam) {
        if (droneInfo.businessPartnerInfoList.some(businessPartnerInfo => {
            return businessPartnerInfo.businessPartnerId === businessPartnerId
        })) {
            // ポート選択の際に選んだビジネスパートナーIDを持っているドローンのみ表示する
            droneInfoList.push(droneInfo);
        };
    };
    setDisplayDroneInfoList(droneInfoList);
    return droneInfoList;
};

/**
 * FD一覧を取得し、ログインユーザーをFDに設定するか判定する
 * @param businessPartnerId 
 * @param setAllFdMap 
 * @param setFdUserInfoList 
 * @param setFdOperationPartnerIdList 
 * @param setfdOperationPartnerId 
 * @param userInfo 
 * @returns true:ログインユーザーをFDに設定する/false:ログインユーザーをFDに設定しない
 */
export const getFdUserInfoList = async (
    businessPartnerId: string,
    allFdUserInfoList: UserInfo[],
    setAllFdUserInfoList: React.Dispatch<React.SetStateAction<UserInfo[]>>,
    setAllFdMap: React.Dispatch<React.SetStateAction<Map<string, UserInfo[]>>>,
    setFdUserInfoList: React.Dispatch<React.SetStateAction<UserInfo[]>>,
    setFdOperationPartnerIdList: React.Dispatch<React.SetStateAction<string[]>>,
    setfdOperationPartnerId: React.Dispatch<React.SetStateAction<string>>,
    userInfo?: UserInfo): Promise<boolean> => {
    let newFdMap = new Map<string, UserInfo[]>();
    let allFdUserInfoListParam
    if (allFdUserInfoList.length === 0) {
        // 全てのFD一覧情報を持っていない場合、取得する
        let apiConnector: APIConnector = APIConnector.instance;
        allFdUserInfoListParam = await apiConnector.getFlightDirectorList();
        setAllFdUserInfoList(allFdUserInfoListParam);
    } else {
        allFdUserInfoListParam = [...allFdUserInfoList];
    };
    for (let fdUserInfo of allFdUserInfoListParam) {
        /*
        * フライト作成で最初に選んだビジネスパートナーIDが、
        * FDの閲覧可能なビジネスパートナーIDリストに存在する場合、
        * FDをビジネスパートナーごとに分類し、fdMapに追加する
        */
        if ((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).
            visibleBusinessPartnerInfoList.some(
                visibleBusinessPartnerInfo => {
                    return visibleBusinessPartnerInfo.businessPartnerId
                        === businessPartnerId
                })
        ) {
            if (!newFdMap?.has((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId)) {
                newFdMap.set((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId,
                    [fdUserInfo]);
            } else {
                newFdMap.get((fdUserInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId)?.push(fdUserInfo);
            };
        };
        setAllFdMap(newFdMap);
    };

    // fdMapのkey(オペレーションパートナーID)をfdOperationPartnerIdListにセットする
    setFdOperationPartnerIdList(Array.from(newFdMap.keys()));

    // デフォルトでログインユーザーのオペレーションパートナーIDが表示されるように設定する
    if (userInfo && (userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId) {
        // ログインユーザー情報が取得できた場合、ログインユーザーのオペレーションパートナーIDのユーザー一覧を作成
        let myPartnerIdFdUserInfoList
            = newFdMap.get((userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId);

        if (myPartnerIdFdUserInfoList && myPartnerIdFdUserInfoList.length > 0) {
            // ログインユーザーのオペレーションパートナーIDのユーザー一覧をFD一覧として設定する
            setFdUserInfoList([...myPartnerIdFdUserInfoList]);
            setfdOperationPartnerId(
                (userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId);
            if (myPartnerIdFdUserInfoList.some(myPartnerIdFdUserInfo => {
                return myPartnerIdFdUserInfo.sub === userInfo.sub
            })) {
                // ログインユーザーをFDとして設定する場合はtrueを返す
                return true;
            };
        };
    };
    return false;
};

export const isDisableSaveButton = (newFlightParam: InputValueType): boolean => {
    if (!newFlightParam.flightDate
        || !newFlightParam.std
        || !newFlightParam.sta
        || !newFlightParam.businessPartnerId
        || !newFlightParam.departurePortId
        || !newFlightParam.arrivalPortId
        || !newFlightParam.purpose
        || !newFlightParam.droneRegistrationId
        || !newFlightParam.fdUserSub
    ) {
        return true;
    } else {
        return false;
    };
};

export const putNewFlight = async (newFlightParam: InputValueType,
    setFlightCreateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setDisplayLoadingMark: React.Dispatch<React.SetStateAction<boolean>>,
    setSavedFlightInfo?: React.Dispatch<React.SetStateAction<boolean>>) => {
    setDisplayLoadingMark(true);
    let apiConnector: APIConnector = APIConnector.instance;
    return await apiConnector.putFlight(
        newFlightParam.flightDate,
        newFlightParam.departurePortId,
        newFlightParam.arrivalPortId,
        newFlightParam.droneRegistrationId,
        newFlightParam.std,
        newFlightParam.sta,
        newFlightParam.fdUserSub,
        newFlightParam.payload,
        newFlightParam.purpose,
        newFlightParam.specificList,
        newFlightParam.orderId !== "" ? newFlightParam.orderId : undefined,
        newFlightParam.businessPartnerId
    ).then((value) => {
        if (setSavedFlightInfo) {
            setSavedFlightInfo(true);
        };
        setFlightCreateDialogOpen(false);
        setDisplayLoadingMark(false);

        return value as string;
    }).catch((error) => {
        console.log(error);
        setDisplayLoadingMark(false);
        if (axios.isAxiosError(error)
            && typeof error.response !== "undefined"
            && error.response.status === 403) {
            throw new Error("フライト作成の権限がありません");
        } else {
            throw new Error("新規フライトの作成中にエラーが発生しました。");
        };
    });
};