import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import NotesIcon from '@mui/icons-material/Notes';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ScaleIcon from '@mui/icons-material/Scale';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Card, CardContent, Fab, Grid, Hidden, SvgIcon, TextField, Typography, Zoom } from "@mui/material";
import { FlightInfo, FlightStatus, FunctionPrivilege, UserInfo } from "adoms-common-lib";
import React from 'react';
import { ReactComponent as DroneIcon } from "../../assets/icons/quadcopter.svg";
import { DisplayInfoGrid } from '../atoms/DisplayInfoGrid';
import FlightStatusInfo from '../atoms/FlightStatusInfo';
import FlightIcon from '@mui/icons-material/Flight';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import EditIcon from '@mui/icons-material/Edit';
import { havePrivilege } from '../../common/PrivilegeUtil';
import { StatusInfoList } from '../organisms/StatusInfoList';

type OrderCardProps = {
    flightInfo: FlightInfo
    changeRemarksTextfieldValue: (event: any) => void
    inputRemarks: string
    changeUnsafeEventTextfieldValue: (event: any) => void
    unsafeEvent: string
    userInfo?: UserInfo
    handleStatusClick: (event: React.MouseEvent<HTMLElement>) => void
    handleStatusClose: () => void
    handleStatusChange: (event: React.MouseEvent<HTMLElement>, newStatus: FlightStatus) => void
    anchorEl: HTMLElement | null
}

export const FlightCardForFlightDetailView: React.FC<OrderCardProps> = (props) => {

    return (
        <Card>
            <CardContent>
                <Grid container direction="column" spacing={1} alignItems="stretch">
                    <Grid item container direction="row" spacing={2}>
                        <Grid item>
                            <FlightStatusInfo status={props.flightInfo.status} />
                        </Grid>
                        {havePrivilege(FunctionPrivilege.PrivilegeFunctionChangeFlightStatus, props.userInfo) ?
                            <Grid item>
                                <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                                    <Fab color="primary" aria-label="Edit" size="small" onClick={props.handleStatusClick}>
                                        <EditIcon />
                                    </Fab>
                                </Zoom>
                                <StatusInfoList
                                    handleStatusClose={props.handleStatusClose}
                                    handleStatusChange={props.handleStatusChange}
                                    anchorEl={props.anchorEl}
                                    flightInfo={props.flightInfo} />
                            </Grid>
                            : undefined}
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" component="div" style={{ marginBottom: "5px", textAlign: "left" }}>
                            {props.flightInfo.businessPartnerId + " " + props.flightInfo.id}
                        </Typography>
                    </Grid>
                    <Grid item container direction="row">
                        <DisplayInfoGrid
                            icon={<FlightTakeoffIcon fontSize='small' sx={{ color: "#797979" }} />}
                            title="FROM"
                            value={props.flightInfo.departure.name} />
                        <DisplayInfoGrid
                            icon={<FlightLandIcon fontSize='small' sx={{ color: "#797979" }} />}
                            title="TO"
                            value={props.flightInfo.arrival.name} />
                    </Grid>
                    <Grid item container direction="row">
                        <DisplayInfoGrid
                            icon={<ScheduleIcon fontSize='small' sx={{ color: "#797979" }} />}
                            title="STD－STA"
                            value={props.flightInfo.std + "－" + props.flightInfo.sta} />
                        <DisplayInfoGrid
                            icon={<WatchLaterIcon fontSize='small' sx={{ color: "#797979" }} />}
                            title="ETD－ETA"
                            value={props.flightInfo.etd + "－" + props.flightInfo.eta} />
                    </Grid>
                    <Hidden only={["xl", "lg", "md", "sm"]}>
                        <Grid item container direction="row">
                            <DisplayInfoGrid
                                icon={<SupportAgentIcon fontSize='small' sx={{ color: "#797979" }} />}
                                title="FD"
                                value={props.flightInfo.fd.name}
                                xs={12} />
                        </Grid>
                        <Grid item container direction="row">
                            <DisplayInfoGrid
                                icon={
                                    <SvgIcon fontSize='small' sx={{ color: "#797979" }}>
                                        <DroneIcon />
                                    </SvgIcon>
                                }
                                title="使用機材"
                                value={props.flightInfo.drone.label} />
                            <DisplayInfoGrid
                                icon={<ScaleIcon fontSize='small' sx={{ color: "#797979" }} />}
                                title="最大ペイロード"
                                value={props.flightInfo.payload + "g"} />
                        </Grid>
                    </Hidden>
                    <Hidden only={"xs"}>
                        <Grid item container direction="row">
                            <DisplayInfoGrid
                                icon={<SupportAgentIcon fontSize='small' sx={{ color: "#797979" }} />}
                                title="FD"
                                value={props.flightInfo.fd.name} />
                            <DisplayInfoGrid
                                icon={
                                    <SvgIcon fontSize='small' sx={{ color: "#797979" }}>
                                        <DroneIcon />
                                    </SvgIcon>
                                }
                                title="使用機材"
                                value={props.flightInfo.drone.label} />
                            <DisplayInfoGrid
                                icon={<ScaleIcon fontSize='small' sx={{ color: "#797979" }} />}
                                title="最大ペイロード"
                                value={props.flightInfo.payload + "g"} />
                        </Grid>
                    </Hidden>
                    <Grid item container direction="row">
                        <DisplayInfoGrid
                            icon={<FlightIcon fontSize='small' sx={{ color: "#797979" }} />}
                            title="飛行目的"
                            value={props.flightInfo.purpose} />
                        <DisplayInfoGrid
                            icon={
                                <AnnouncementIcon fontSize='small' sx={{ color: "#797979" }} />
                            }
                            title="特定飛行"
                            value={props.flightInfo.specificList?.join("、")} />
                    </Grid>
                    <Grid item container direction="row">
                        <DisplayInfoGrid
                            xl={6} lg={6} md={6} sm={12} xs={12}
                            icon={
                                <WarningAmberIcon fontSize='small' sx={{ color: "#797979" }} />
                            }
                            title="飛行の安全に影響のあった事項"
                            value={
                                <TextField
                                    value={props.unsafeEvent}
                                    onChange={event => { props.changeUnsafeEventTextfieldValue(event) }}
                                    multiline
                                    maxRows="2"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                        <DisplayInfoGrid
                            xl={6} lg={6} md={6} sm={12} xs={12}
                            icon={
                                <NotesIcon fontSize='small' sx={{ color: "#797979" }} />
                            }
                            title="備考"
                            value={
                                <TextField
                                    value={props.inputRemarks}
                                    onChange={event => { props.changeRemarksTextfieldValue(event) }}
                                    multiline
                                    maxRows="2"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}