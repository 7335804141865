import { DialogContent, DialogTitle, Divider, Grid, InputAdornment, TextField } from "@mui/material";
import { DroneInfo } from "adoms-common-lib";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

// propsの型を定義
type DroneDetailInformationContentporps = {
    droneData: DroneInfo;
    setNewDrone: React.Dispatch<React.SetStateAction<DroneInfo>>;
    setIsNextDisable: React.Dispatch<React.SetStateAction<boolean>>;
    isTextFieldDisabled: boolean;
};

interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            defaultValue={0}
            inputMode='decimal'
            thousandSeparator
            valueIsNumericString
            allowNegative={false}
            suffix="g"
        />
    );
});


// ポート情報ダイアログ
const DroneDetailInformationContent: React.FC<DroneDetailInformationContentporps> = (props) => {

    const [droneKind, setDroneKind] = useState<string>(props.droneData.kind);
    const [droneRegistrationId, setDroneRegistrationId] = useState<string>(props.droneData.registrationId);
    const [droneSerialNumber, setDroneSerialNumber] = useState<string>(props.droneData.serialNumber);
    const [dronePayload, setDronePayload] = useState<number>(props.droneData.payload);

    // 初期値設定
    useEffect(() => {
        const setDefaultValue = () => {
            setDroneKind(props.droneData.kind);
            setDroneRegistrationId(props.droneData.registrationId);
            setDroneSerialNumber(props.droneData.serialNumber);
            setDronePayload(props.droneData.payload);
        };
        setDefaultValue();
        props.setIsNextDisable(true);
    }, []);

    // 全て入力されているかのチェック
    useEffect(() => {
        if (
            props.droneData.kind !== "" &&
            props.droneData.registrationId !== "" &&
            props.droneData.serialNumber !== "" &&
            props.droneData.payload !== 0 &&
            !isNaN(props.droneData.payload)
        ) {
            props.setIsNextDisable(false);
        } else {
            props.setIsNextDisable(true);
        };
    }, [props.droneData]);

    return (
        <>
            {/* ダイアログタイトル */}
            <DialogTitle id="responsive-dialog-title">
                機体詳細情報
                <Divider />
            </DialogTitle>

            {/* ダイアログコンテンツ */}
            <DialogContent>
                <Grid container spacing={3} direction="column">

                    {/** 機体種別 */}
                    <Grid item xs={12} >
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="機体種別"
                            value={droneKind}
                            onChange={(e) => {
                                setDroneKind(e.target.value);
                                props.setNewDrone({
                                    ...props.droneData,
                                    kind: e.target.value
                                });
                            }}
                        />
                    </Grid>

                    {/** 登録番号 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="無人航空機の登録番号"
                            disabled={props.isTextFieldDisabled}
                            value={droneRegistrationId}
                            onChange={(e) => {
                                setDroneRegistrationId(e.target.value);
                                props.setNewDrone({
                                    ...props.droneData,
                                    registrationId: e.target.value
                                });
                            }}
                        />
                    </Grid>

                    {/** 製造番号 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="製造番号"
                            value={droneSerialNumber}
                            onChange={(e) => {
                                setDroneSerialNumber(e.target.value);
                                props.setNewDrone({
                                    ...props.droneData,
                                    serialNumber: e.target.value
                                });
                            }}
                        />
                    </Grid>

                    {/** 最大積載量 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            label="最大積載量"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                            value={dronePayload}
                            onChange={(e) => {
                                setDronePayload(parseInt(e.target.value));
                                props.setNewDrone({
                                    ...props.droneData,
                                    payload: parseInt(e.target.value)
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent >
        </>
    );
};

export default DroneDetailInformationContent;