import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { APIConnector } from "../../connector/APIConnector";
import { Port } from "adoms-common-lib"
import axios from "axios"
import React from "react"

type PortDeleteDialogProps = {
    port?: Port;
    isOpen: boolean;
    closeHandler: () => void;
    setEditPortList: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayLoadingMark: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PortDeleteDialog: React.FC<PortDeleteDialogProps> = (props) => {

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const handleClose = () => {
        props.closeHandler();
        setErrorMessage(undefined);
    };

    const handleDelete = async (event: any) => {
        if (props.port) {
            props.setDisplayLoadingMark(true);
            let apigw: APIConnector = APIConnector.instance;
            await apigw.deletePort(props.port.businessPartnerId, props.port.id).then(() => {
                props.closeHandler();
                props.setEditPortList(true);
                setErrorMessage(undefined);
            }).catch((error) => {
                console.log(error);
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    setErrorMessage("権限がありません");
                    props.setDisplayLoadingMark(false);
                } else {
                    setErrorMessage("ポート情報を削除できませんでした");
                    props.setDisplayLoadingMark(false);
                };
            });
        };
    };

    return (
        <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">ポート情報削除</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    下記ポート情報を削除します。
                </DialogContentText>
                <TextField
                    id="outlined-full-width"
                    label="JSON形式"
                    style={{ margin: 8 }}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    multiline
                    rows={20}
                    defaultValue={JSON.stringify(props.port, null, 2)}
                    disabled
                    error={typeof errorMessage !== "undefined"}
                    helperText={errorMessage}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete} color="secondary">
                    削除
                </Button>
            </DialogActions>
        </Dialog>
    );
};
