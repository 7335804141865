import React, { useEffect, useState } from "react";
import { CertificationKind, CertificationType, DroneInfo } from "adoms-common-lib";
import { Checkbox, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";

// propsの型を定義
type DroneCertificationInformationContentporps = {
    droneData: DroneInfo;
    setNewDrone: React.Dispatch<React.SetStateAction<DroneInfo>>;
    setIsConfirmDisable: React.Dispatch<React.SetStateAction<boolean>>;
};

// ポート情報ダイアログ
const DroneCertificationInformationContent: React.FC<DroneCertificationInformationContentporps> = (props) => {
    const [droneCertificationKind, setDroneCertificationKind] = useState<CertificationKind | undefined>(props.droneData.certification?.kind);
    const [droneCertificationType, setDroneCertificationType] = useState<CertificationType | undefined>(props.droneData.certification?.type);
    const [droneCertificationNumber, setDroneCertificationNumber] = useState<string | undefined>(props.droneData.certification?.number);
    const [droneRemarks, setDroneRemarks] = useState<string | undefined>(props.droneData.remarks);

    // 初期値設定
    useEffect(() => {
        const setDefaultValue = () => {
            if (props.droneData.certification) {
                setDroneCertificationKind(props.droneData.certification.kind);
                setDroneCertificationType(props.droneData.certification.type);
                setDroneCertificationNumber(props.droneData.certification.number);
            }
            if(props.droneData.remarks){
                setDroneRemarks(props.droneData.remarks);
            }   
        }
        setDefaultValue();

        if (droneCertificationKind &&
            droneCertificationType &&
            droneCertificationNumber
        ) {
            props.setIsConfirmDisable(false);
        }
    }, []);

    useEffect(() => {
        if (droneCertificationKind && droneCertificationType && droneCertificationNumber) {
            props.setNewDrone({
                ...props.droneData,
                certification: {
                    kind: droneCertificationKind,
                    type: droneCertificationType,
                    number: droneCertificationNumber
                }
            });
            props.setIsConfirmDisable(false);
        } else if (droneCertificationKind || droneCertificationType || droneCertificationNumber) {
            props.setIsConfirmDisable(true);
        } else {
            const { certification, ...newDroneData } = props.droneData;
            props.setNewDrone(newDroneData);
            props.setIsConfirmDisable(false);
        }
    }, [droneCertificationKind, droneCertificationType, droneCertificationNumber]);

    return (
        <>
            {/* ダイアログタイトル */}
            <DialogTitle id="responsive-dialog-title">
                認証情報
                <Divider />
            </DialogTitle>

            {/* ダイアログコンテンツ */}
            <DialogContent>
                <Grid container spacing={3} direction="column">
                    {/* 認証種別 */}
                    <Grid item>
                        <FormControl variant="filled" size='small' sx={{ width: "218px" }}>
                            <InputLabel>認証種別 - 任意</InputLabel>
                            <Select
                                value={droneCertificationKind}
                                onChange={(e) => {
                                    setDroneCertificationKind(e.target.value as CertificationKind);
                                }}
                            >
                                <MenuItem value={""}>選択しない</MenuItem>
                                <MenuItem value={CertificationKind.Model}>型式認証</MenuItem>
                                <MenuItem value={CertificationKind.Aircraft}>機体認証</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* 認証区分 */}
                    <Grid item>
                        <FormControl variant="filled" size='small' sx={{ width: "218px" }}>
                            <InputLabel>認証種別 - 任意</InputLabel>
                            <Select
                                value={droneCertificationType}
                                onChange={(e) => {
                                    setDroneCertificationType(e.target.value as CertificationType);
                                }}
                            >
                                <MenuItem value={""}>選択しない</MenuItem>
                                <MenuItem value={CertificationType.Tier1}>一種</MenuItem>
                                <MenuItem value={CertificationType.Tier2}>二種</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/** 認証番号 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="認証番号 - 任意"
                            value={droneCertificationNumber}
                            onChange={(e) => {
                                setDroneCertificationNumber(e.target.value);
                            }}
                        />
                    </Grid>

                    {/** 備考 */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            variant="filled"
                            type="text"
                            label="備考 - 任意"
                            value={droneRemarks}
                            onChange={(e) => {
                                setDroneRemarks(e.target.value);
                                props.setNewDrone({
                                    ...props.droneData,
                                    remarks: e.target.value
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent >
        </>
    );
};

export default DroneCertificationInformationContent;