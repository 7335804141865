import dayjs, { Dayjs } from 'dayjs';
import type { CircleLayer, FillLayer, LineLayer, SymbolLayer } from 'react-map-gl';

/**
 * 分数を10分毎になるよう繰り上げる
 * @param dayjs 
 * @returns 
 */
export const changeMinutes10MinuteIncrements = (dayjs: Dayjs): Dayjs => {

    const unit = "minute";
    const amount = 10;
    const minutesNumber = Number(dayjs.get(unit));
    if (minutesNumber % amount === 0) {
        return dayjs.startOf(unit);
    } else {
        return dayjs.add(amount - (dayjs.get(unit) % amount), unit)
            .startOf(unit);
    };
};

/**
 * 分数を四捨五入して10分毎になるよう繰り上げる
 * @param dayjs 
 * @returns 
 */
export const changeMinutes10MinuteRoundingOff = (dayjs: Dayjs): Dayjs => {

    const unit = "minute";
    const amount = 10;
    const minutesNumber = Number(dayjs.get(unit));
    if (minutesNumber % amount === 0) {
        return dayjs.startOf(unit);
    } else {
        const remainder = dayjs.get(unit) % amount;
        if (remainder < 5) {
            return dayjs.add(-(dayjs.get(unit) % amount), unit)
                .startOf(unit);
        } else {
            return dayjs.add(amount - (dayjs.get(unit) % amount), unit)
                .startOf(unit);
        };
    };
};

/**
 * 降水量の配色見本
 */
export const rainSampleColorList = [
    {
        color: "#f90000",
        value: 12
    },
    {
        color: "#f99700",
        value: 10
    },
    {
        color: "#edf900",
        value: 8
    },
    {
        color: "#35f900",
        value: 6
    },
    {
        color: "#0ce4f6",
        value: 4
    },
    {
        color: "#6666ff",
        value: 2
    },
    {
        color: "#ccccff",
        value: 1
    },
    {
        color: "#ffffff",
        value: 0
    }
];

/**
 * 風速の配色見本
 */
export const windSpeedSampleColorList = [
    {
        color: "#4b0082",
        value: 15
    },
    {
        color: "#800080",
        value: 13
    },
    {
        color: "#f90000",
        value: 11
    },
    {
        color: "#f99700",
        value: 9
    },
    {
        color: "#edf900",
        value: 7
    },
    {
        color: "#35f900",
        value: 5
    },
    {
        color: "#00ffff",
        value: 3
    },
    {
        color: "#ffffff",
        value: 0
    }
];

export const lineLayerStyle: LineLayer = {
    id: 'line',
    type: 'line',
    paint: {
        "line-color": "#ea9245",
        "line-width": 3
    }
};

export const pointLayerStyle: CircleLayer = {
    id: 'point',
    type: 'circle',
    paint: {
        'circle-radius': 5,
        'circle-color': '#ea9245'
    }
};

export const nameLabelFilteredLayerStyle: SymbolLayer = {
    id: 'name-label',
    type: 'symbol',
    layout: {
        'text-field': ['get', 'name'], // geojsonのpropertiesのnameを表示
        'text-anchor': 'top-left', // テキストの表示位置
        'text-justify': 'auto', // テキストの整列
        'text-font': ['Fira GO Regular'],
        'text-size': 12, // テキストサイズを指定
        'text-allow-overlap': true, // 文字が重なっても表示されるようにする
    },
    filter: [
        "all",
        ["!=", ["get", "visibility"], false], // 必要に応じてフィルタを追加
        ["==", ["get", "stroke"], null]
    ]
};

export const nameLabelLayerStyle: SymbolLayer = {
    id: 'name-label',
    type: 'symbol',
    layout: {
        'text-field': ['get', 'name'], // geojsonのpropertiesのnameを表示
        'text-anchor': 'top-left', // テキストの表示位置
        'text-justify': 'auto', // テキストの整列
        'text-font': ['Fira GO Regular'],
        'text-size': 12, // テキストサイズを指定
        'text-allow-overlap': true, // 文字が重なっても表示されるようにする
    },
    filter: ["==", ["get", "stroke"], null]
};

export const rainFillLayerStyle: FillLayer = {
    id: 'rain',
    type: 'fill',
    paint: {
        "fill-color": [
            "interpolate",
            ["linear"],
            ["get", "rain"],
            0, "transparent",
            1, rainSampleColorList[6].color,
            2, rainSampleColorList[5].color,
            4, rainSampleColorList[4].color,
            8, rainSampleColorList[3].color,
            16, rainSampleColorList[2].color,
            32, rainSampleColorList[1].color,
            64, rainSampleColorList[0].color
        ],
        "fill-opacity": 0.5,
        "fill-outline-color": "transparent"
    }
};

export const windSymbolLayerStyle = (): SymbolLayer => {

    return {
        id: 'wind-icons',
        type: 'symbol',
        layout: {
            'icon-image': "wind-Animation",
            'icon-rotate': ['+', ['get', 'windDirection'], 180],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                7, // ズームレベル7のときのアイコンサイズ
                0.3,
                11, // ズームレベル11のときのアイコンサイズ
                1,
                15, // ズームレベル15のときのアイコンサイズ
                2,
            ]
        }
    };
};

export const windSpeedFillLayerStyle: FillLayer = {
    id: 'wind-fill',
    type: 'fill',
    paint: {
        "fill-color": [
            'interpolate',
            ['exponential', 0.5],
            ['get', 'windSpeed'],
            0,
            "transparent",
            3,
            windSpeedSampleColorList[6].color,
            5,
            windSpeedSampleColorList[5].color,
            7,
            windSpeedSampleColorList[4].color,
            9,
            windSpeedSampleColorList[3].color,
            11,
            windSpeedSampleColorList[2].color,
            13,
            windSpeedSampleColorList[1].color,
            15,
            windSpeedSampleColorList[0].color,
        ],
        "fill-opacity": 0.5,
        "fill-outline-color": "transparent"
    }
};

export const isWeatherAPIAccessible = (): boolean => {
    const nowMinutes = Number(dayjs().format("mm"));

    if (21 <= nowMinutes
        && nowMinutes <= 23) {
        // 毎時21分～23分の場合
        return false;
    } else {
        return true;
    };
};

/**
 * 表示する気象情報の種類
 */
export const WeatherInformationKind = {
    /**
     * 降水量
     */
    rain: "rain",
    /**
     * 風向・風速
     */
    wind: "wind"
} as const;
export type WeatherInformationKind = typeof WeatherInformationKind[keyof typeof WeatherInformationKind];

/**
 * 風のアニメーションを作成する
 * @param map 
 * @param size 
 * @param canvasRef 
 */
export const handleMapOnLoad = (map: mapboxgl.Map | undefined, size: number, canvasRef: React.MutableRefObject<any>) => {
    if (map) {
        if (!map.hasImage("wind-Animation")) {
            const windAnimation = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                render() {
                    const duration = 2000;

                    const ctx = canvasRef.current.getContext("2d");
                    // 縦軸の長さ
                    let y = 50;
                    // 線の始点を遅らせる時間
                    const startDelayTime = 1 - (performance.now() % duration) / 1000;
                    // 線の終点を遅らせる時間
                    const lastDelayTime = 1 - (performance.now() % duration) / 2000;
                    // グラデーション
                    const gradient = ctx.createLinearGradient(25, 0, 25, 50);
                    gradient.addColorStop(0, "blue");
                    gradient.addColorStop(1, "white");

                    // 線の描画
                    ctx.strokeStyle = gradient;
                    ctx.clearRect(0, 0, this.width, this.height);
                    ctx.lineWidth = 3
                    ctx.beginPath();
                    ctx.moveTo(15, y * startDelayTime);
                    ctx.lineTo(15, y * lastDelayTime);
                    ctx.closePath();
                    ctx.stroke();

                    // 画像のデータをキャンバスのデータで更新
                    this.data = ctx.getImageData(0, 0, this.width, this.height).data;

                    // マップを継続的に再描画
                    map.triggerRepaint();

                    // 画像が更新されたことをマップに知らせる
                    return true;
                }
            };

            map.addImage("wind-Animation", windAnimation, {
                pixelRatio: 2
            });
        }
    }
};
