import { TableRow } from "@aws-amplify/ui-react";
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Alert, Avatar, CircularProgress, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { FlightInfo, FlightTimeInformation, WaypointWeatherGeoJson } from "adoms-common-lib";
import dayjs, { extend } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import React from 'react';
import { ETAAdjustDialog } from "../molecule/ETAAdjustDialog";
extend(duration);

const TitlePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    backgroundColor: "#EFEFEF",
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

const ValuePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

type WaypointWindProps = {
    selectedFlightInfo: FlightInfo | undefined
    waypointWeatherGeoJson: WaypointWeatherGeoJson | undefined
    setWaypointWeatherGeoJson: React.Dispatch<React.SetStateAction<WaypointWeatherGeoJson | undefined>>
    waypointWindErrorMessage: string | undefined
    setWaypointWindErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
    isDisplayWaypointWindLoading: boolean
    setDisplayWaypointWindLoading: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedFlightEtd: React.Dispatch<React.SetStateAction<string | undefined>>
    selectedFlightEtd: string | undefined
    routeDataMap: Map<string, FeatureCollection<Geometry, GeoJsonProperties> | undefined> | undefined
    routeErrorMessage?: string
    takeoffDuration1: duration.Duration
    setTakeoffDuration1: React.Dispatch<React.SetStateAction<duration.Duration>>
    landingDuration1: duration.Duration
    setLandingDuration1: React.Dispatch<React.SetStateAction<duration.Duration>>
    takeoffDuration2: duration.Duration
    setTakeoffDuration2: React.Dispatch<React.SetStateAction<duration.Duration>>
    landingDuration2: duration.Duration
    setLandingDuration2: React.Dispatch<React.SetStateAction<duration.Duration>>
    transitDuration: duration.Duration
    setTransitDuration: React.Dispatch<React.SetStateAction<duration.Duration>>
    isFilter: boolean
    setFilter: React.Dispatch<React.SetStateAction<boolean>>
}

export const WaypointWindGrid: React.FC<WaypointWindProps> = (props) => {
    const [isETAAdjustDialogOpen, setETAAdjustDialogOpen] = React.useState(false);

    const calcETA = (flightTimeInformationList: FlightTimeInformation[]) => {
        let gsFlightTimeTotalSec = 0;
        flightTimeInformationList.map((flightTimeInformation) => {
            gsFlightTimeTotalSec = gsFlightTimeTotalSec + flightTimeInformation.gsFlightTimeTotalSec;
        });
        let etaDayJs = dayjs(props.selectedFlightInfo?.flightDate + " " + props.selectedFlightEtd);
        etaDayJs = etaDayJs.add(gsFlightTimeTotalSec, "seconds");
        etaDayJs = etaDayJs.add(props.takeoffDuration1).add(props.landingDuration1)
            .add(props.takeoffDuration2).add(props.landingDuration2).add(props.transitDuration);
        return (<Grid item>
            <ValuePaper elevation={0} variant="outlined" >
                <Typography variant='subtitle2' whiteSpace={"pre-wrap"} sx={{ color: "#0000cd", fontWeight: "bold" }}>{etaDayJs.format("HH:mm:ss")}</Typography>
            </ValuePaper>
        </Grid>);
    };

    const calcGsTotalFlightTime = (flightTimeInformationList: FlightTimeInformation[]) => {
        let gsFlightTimeTotalSec = 0;
        flightTimeInformationList.map((flightTimeInformation) => {
            gsFlightTimeTotalSec = gsFlightTimeTotalSec + flightTimeInformation.gsFlightTimeTotalSec;
        });
        extend(duration);
        let etaDayJs = dayjs.duration(gsFlightTimeTotalSec, "second");
        etaDayJs = etaDayJs.add(props.takeoffDuration1).add(props.landingDuration1)
            .add(props.takeoffDuration2).add(props.landingDuration2).add(props.transitDuration);
        return (<Grid item>
            <ValuePaper elevation={0} variant="outlined" >
                <Typography variant='subtitle2' whiteSpace={"pre-wrap"} sx={{ color: "#0000cd", fontWeight: "bold" }}>{etaDayJs.format("HH:mm:ss")}</Typography>
            </ValuePaper>
        </Grid>);
    };

    const displayTableRow = () => {
        let tableRowList: JSX.Element[] = new Array();
        if (props.waypointWeatherGeoJson && props.routeDataMap) {
            let gsFlightTimeTotalSec = 0;
            for (let index = 0; index < props.waypointWeatherGeoJson.flightTimeInformationList.length; index++) {
                let features = props.routeDataMap.get(props.selectedFlightInfo!.id)?.features;
                let reverseFlg = false;
                if (features) {
                    features.find((feature) => {
                        const name = feature?.properties?.name;
                        if (name != null && name.includes("飛行経路")) {
                            const nameParts = name.split("_");
                            if (nameParts[1] === props.selectedFlightInfo?.arrival.id
                                && nameParts[2] === props.selectedFlightInfo?.departure.id) {
                                reverseFlg = true;
                            };
                            return true;
                        };
                    });

                    features = features.filter((feature) => {
                        return feature.geometry.type === "Point";
                    });

                    if (reverseFlg) {
                        features.reverse();
                    };

                    const feature = features[index + 1];

                    if (feature) {

                        const flightTimeInformation = props.waypointWeatherGeoJson.flightTimeInformationList[index];
                        gsFlightTimeTotalSec = gsFlightTimeTotalSec + flightTimeInformation.gsFlightTimeTotalSec;
                        let etaDayJs = dayjs(props.selectedFlightInfo?.flightDate + " " + props.selectedFlightEtd);
                        etaDayJs = etaDayJs.add(gsFlightTimeTotalSec, "seconds");

                        if (props.selectedFlightInfo?.businessPartnerId === "OKINAWA_BLOOD") {
                            if (props.selectedFlightInfo.departure.name === "那覇"
                                && props.selectedFlightInfo.arrival.name === "名護") {
                                if (index >= 0 && props.takeoffDuration1) {
                                    etaDayJs = etaDayJs.add(props.takeoffDuration1);
                                }
                                if (index >= 11 && props.landingDuration1) {
                                    etaDayJs = etaDayJs.add(props.landingDuration1);
                                }
                                if (index >= 12 && props.transitDuration) {
                                    etaDayJs = etaDayJs.add(props.transitDuration);
                                }
                                if (index >= 12 && props.takeoffDuration2) {
                                    etaDayJs = etaDayJs.add(props.takeoffDuration2);
                                }
                                if (index >= 21 && props.landingDuration2) {
                                    etaDayJs = etaDayJs.add(props.landingDuration2);
                                }
                            } else if (props.selectedFlightInfo.departure.name === "名護"
                                && props.selectedFlightInfo.arrival.name === "那覇") {
                                if (index >= 0 && props.takeoffDuration1) {
                                    etaDayJs = etaDayJs.add(props.takeoffDuration1);
                                }
                                if (index >= 9 && props.landingDuration1) {
                                    etaDayJs = etaDayJs.add(props.landingDuration1);
                                }
                                if (index >= 10 && props.transitDuration) {
                                    etaDayJs = etaDayJs.add(props.transitDuration);
                                }
                                if (index >= 10 && props.takeoffDuration2) {
                                    etaDayJs = etaDayJs.add(props.takeoffDuration2);
                                }
                                if (index >= 21 && props.landingDuration2) {
                                    etaDayJs = etaDayJs.add(props.landingDuration2);
                                }
                            }
                        }

                        if (props.isFilter) {
                            if (feature.properties?.visibility !== false) {
                                tableRowList.push(
                                    <TableRow>
                                        <TableCell align="center">{feature.properties?.name}</TableCell>
                                        <TableCell align="center" sx={{ color: "#0000cd", fontWeight: "bold" }}>{etaDayJs.format("HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{flightTimeInformation.gsFlightTime_hhmmss}</TableCell>
                                        <TableCell align="center">{flightTimeInformation.gs_msec.toString()}</TableCell>
                                        <TableCell align="center">{flightTimeInformation.distance_m.toString()}</TableCell>
                                        <TableCell align="center">{flightTimeInformation.course_deg.toString()}</TableCell>
                                        <TableCell align="center">{flightTimeInformation.wca_deg.toString()}</TableCell>
                                    </TableRow>)
                            }
                        } else {
                            tableRowList.push(
                                <TableRow>
                                    <TableCell align="center">{feature.properties?.name}</TableCell>
                                    <TableCell align="center" sx={{ color: "#0000cd", fontWeight: "bold" }}>{etaDayJs.format("HH:mm:ss")}</TableCell>
                                    <TableCell align="center">{flightTimeInformation.gsFlightTime_hhmmss}</TableCell>
                                    <TableCell align="center">{flightTimeInformation.gs_msec.toString()}</TableCell>
                                    <TableCell align="center">{flightTimeInformation.distance_m.toString()}</TableCell>
                                    <TableCell align="center">{flightTimeInformation.course_deg.toString()}</TableCell>
                                    <TableCell align="center">{flightTimeInformation.wca_deg.toString()}</TableCell>
                                </TableRow>)
                        }
                    }
                }
            }
        }
        return tableRowList;
    }

    return (
        props.isDisplayWaypointWindLoading ?
            <Grid textAlign={"center"}>
                <CircularProgress />
            </Grid >
            :
            props.selectedFlightInfo && props.waypointWeatherGeoJson
                && props.waypointWeatherGeoJson.flightTimeInformationList.length > 0 ?
                props.waypointWindErrorMessage ?
                    <Grid item xs>
                        <Alert severity="error" >{props.waypointWindErrorMessage}</Alert>
                    </Grid> :
                    <>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Grid item container spacing={1} wrap={"nowrap"}>
                                    <Grid item>
                                        <TitlePaper elevation={0}>
                                            <Typography variant='subtitle2' whiteSpace={"pre"}>ETA</Typography>
                                        </TitlePaper>
                                    </Grid>
                                    {calcETA(props.waypointWeatherGeoJson.flightTimeInformationList)}
                                    <Grid item>
                                        <TitlePaper elevation={0}>
                                            <Typography variant='subtitle2' whiteSpace={"pre"}>総飛行時間</Typography>
                                        </TitlePaper>
                                    </Grid>
                                    {calcGsTotalFlightTime(props.waypointWeatherGeoJson.flightTimeInformationList)}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    {props.selectedFlightInfo.businessPartnerId === "OKINAWA_BLOOD" && (
                                        (props.selectedFlightInfo.departure.name === "那覇"
                                            && props.selectedFlightInfo.arrival.name === "名護") ||
                                        (props.selectedFlightInfo.departure.name === "名護"
                                            && props.selectedFlightInfo.arrival.name === "那覇")) ?
                                        <Grid item>
                                            <Tooltip title="ETA滞空・作業時間調整">
                                                <IconButton
                                                    onClick={() => setETAAdjustDialogOpen(true)}
                                                    size="small">
                                                    <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                                                        <EditIcon />
                                                    </Avatar>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid> : undefined}
                                    <Grid item>
                                        <Tooltip title="フィルター">
                                            <IconButton
                                                onClick={() => props.setFilter(!props.isFilter)}
                                                size="small">
                                                <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                                                    {props.isFilter ?
                                                        <FilterAltIcon /> : <FilterAltOffIcon />}
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1} >
                                <TableContainer sx={{ maxHeight: `calc(100vh - 260px)`, overflow: 'auto' }}>
                                    <Table size="small">
                                        <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: "#EFEFEF", zIndex: 1, whiteSpace: "nowrap" }}>
                                            <TableRow>
                                                <TableCell align="center">waypoint</TableCell>
                                                <TableCell align="center">ETA</TableCell>
                                                <TableCell align="center">飛行時間</TableCell>
                                                <TableCell align="center">GS(m/s)</TableCell>
                                                <TableCell align="center">距離(m)</TableCell>
                                                <TableCell align="center">Course(deg)</TableCell>
                                                <TableCell align="center">WCA(deg)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {displayTableRow()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <ETAAdjustDialog
                            isETAAdjustDialogOpen={isETAAdjustDialogOpen}
                            setETAAdjustDialogOpen={setETAAdjustDialogOpen}
                            takeoffDuration1={props.takeoffDuration1}
                            setTakeoffDuration1={props.setTakeoffDuration1}
                            LandingDuration1={props.landingDuration1}
                            setLandingDuration1={props.setLandingDuration1}
                            takeoffDuration2={props.takeoffDuration2}
                            setTakeoffDuration2={props.setTakeoffDuration2}
                            LandingDuration2={props.landingDuration2}
                            setLandingDuration2={props.setLandingDuration2}
                            transitDuration={props.transitDuration}
                            setTransitDuration={props.setTransitDuration} />
                    </>
                :
                <Alert severity="info" >フライトを選択するとwaypointごとのETAが表示されます</Alert>
    )
}