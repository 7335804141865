import React, { useState } from "react";
import axios from 'axios';
import { APIConnector } from '../../connector/APIConnector';
import { DroneInfo } from "adoms-common-lib";
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, Grid, Paper, styled, Typography, } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

// propsの型を定義
type DroneDeleteDialogporps = {
    isOpen: boolean;
    closeHandler: () => void;
    deleteDroneData: DroneInfo;
    setEditDroneList: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayLoadingMark: React.Dispatch<React.SetStateAction<boolean>>;
};

// 機体情報ダイアログ
const DroneDeleteDialog: React.FC<DroneDeleteDialogporps> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    // 画面に表示するビジネスパートナーの名前だけを取り出す
    const setBusinessPartner = () => {
        return props.deleteDroneData.businessPartnerInfoList.map((businessPartnerInfo) => {
            return (
                <Chip
                    sx={{ margin: 0.3 }}
                    label={businessPartnerInfo.businessPartnerName} />
            );
        });
    };

    // ダイアログが閉じられたとき
    const handleClose = () => {
        setErrorMessage(undefined);
        props.closeHandler();
    };

    // 削除ハンドラ
    const handleDelete = async (event: any) => {
        if (props.deleteDroneData) {
            props.setDisplayLoadingMark(true);
            setErrorMessage(undefined);
            let apigw: APIConnector = APIConnector.instance;
            await apigw.deleteDrone(props.deleteDroneData).then(() => {
                props.closeHandler();
                props.setEditDroneList(true);
                setErrorMessage(undefined);
            }).catch((error) => {
                console.log(error);
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    setErrorMessage("権限がありません");
                    props.setDisplayLoadingMark(false);
                } else {
                    setErrorMessage("機体情報を削除できませんでした");
                    props.setDisplayLoadingMark(false);
                };
            });
        };
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => {
                handleClose();
            }}>
            {errorMessage ?
                <Alert severity="error" sx={{ margin: "20px" }}>{errorMessage}</Alert> :
                null}
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: 'column', paddingTop: "3%" }}>
                <DeleteForeverOutlinedIcon fontSize="large" color="warning" />
            </Box>
            {/* ダイアログタイトル */}
            <Box p={1} sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant={"h6"}>{"下記の機体を削除しますか？"}</Typography>
            </Box>

            <DialogContent sx={{ paddingTop: "0%" }}>
                <Box p={2} sx={{ borderRadius: '10px', maxWidth: "360px", backgroundColor: "#eeeeee" }}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={5}>
                            {"機体名"}
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>{props.deleteDroneData.label}</Typography>
                        </Grid>

                        <Grid item xs={5}>
                            {"保有元"}
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>{props.deleteDroneData.owner}</Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Typography lineHeight={1} sx={{ whiteSpace: 'pre-line' }}>
                                {"無人航空機\nの登録番号"}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography>{props.deleteDroneData.registrationId}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={5}>
                                    <Typography lineHeight={1} sx={{ whiteSpace: 'pre-line' }}>
                                        {"ビジネス\nパートナー"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    {setBusinessPartner()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", paddingTop: "0", paddingBottom: "3%" }}>
                <Button variant="outlined" color="primary" size="large" onClick={handleClose} autoFocus sx={{ width: '45%' }}>
                    いいえ
                </Button>
                <Button variant="contained" color="warning" size="large" onClick={handleDelete} sx={{ width: '45%', }}>
                    はい
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DroneDeleteDialog;