import GetAppIcon from '@mui/icons-material/GetApp';
import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DroneInfo, UserInfo } from "adoms-common-lib";
import axios from 'axios';
import * as Excel from 'exceljs';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { APIConnector } from "../../connector/APIConnector";

const useStyles = makeStyles()((theme: any) => ({
    textField: {
        width: 200,
    },
    button: {
        marginTop: theme.spacing(1)
    },

    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));
type FlightLogOutputDialogProps = {
    onClose: () => void
    isOpen: boolean
}

export const FlightLogOutputDialog: React.FC<FlightLogOutputDialogProps> = (props) => {
    useEffect(() => {
        //ドローン一覧とFD一覧を取得
        const fetchData = async () => {
            setDisplayLoadingMark(true)
            let c: APIConnector = APIConnector.instance;
            let getDroneList = c.getDroneList();
            let getFlightDirectorList = c.getFlightDirectorList();
            await Promise.all([getDroneList, getFlightDirectorList]).then(value => {
                setDroneList(value[0]);
                setFDList(value[1] as UserInfo[]);
                setDisplayLoadingMark(false)
            }).catch(error => {
                console.log("error:" + error.message);
                setErrorMessage("使用機材一覧、フライトディレクター一覧が取得できませんでした");
                setDisplayLoadingMark(false)
            })
        };

        if (props.isOpen) {
            fetchData();
        };
    }, [props.isOpen])

    const { classes } = useStyles();
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const [flightDateFrom, setFlightDateFrom] = React.useState("");
    const [flightDateTo, setFlightDatedTo] = React.useState("");
    const [droneList, setDroneList] = React.useState<DroneInfo[]>([]);
    const [fdList, setFDList] = React.useState<UserInfo[]>([]);
    const [droneRegistrationId, setDroneRegistrationId] = React.useState("");
    const [fdSub, setFdId] = React.useState("指定なし");
    const [droneIdErrorMessage, setDroneIdErrorMessage] = React.useState<string>();
    const [flightDateFromErrorMessage, setFlightDateFromErrorMessage] = React.useState<string>();
    const [flightDateToErrorMessage, setFlightDateToErrorMessage] = React.useState<string>();
    const [infoMessage, setInfoMessage] = React.useState<string>();
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    /**
    * ダイアログをクローズする
    */
    const handleFlightLogOutputDialogClose = () => {
        setDroneRegistrationId("");
        setFdId("指定なし");
        setDroneIdErrorMessage("");
        setFlightDateFromErrorMessage("");
        setFlightDateToErrorMessage("");
        setInfoMessage("");
        setErrorMessage("");
        props.onClose();
    };

    /**
    * 対象期間開始日が変わった時のハンドラ
    * @param event 
    */
    const handleChangeFlightDateFrom = (event: any) => {
        setFlightDateFrom(event.target.value);
    };

    /**
    * 対象期間終了日が変わった時のハンドラ
    * @param event 
    */
    const handleChangeFlightDateTo = (event: any) => {
        setFlightDatedTo(event.target.value);
    };

    /**
    * ドローンが変わった時のハンドラ
    * @param event 
    */
    const handleChangeDrone = (event: any) => {
        if (event.target.value != "") {
            setDroneRegistrationId(event.target.value);
        } else {
            setDroneRegistrationId("");
        }
    };

    /**
     * FDが変わった時のハンドラ
     * @param event 
     */
    const handleChangeFD = (event: any) => {
        if (event.target.value != "指定なし") {
            setFdId(event.target.value);
        } else {
            setFdId("指定なし")
        }
    };

    /**
     * Excel出力ボタンが押された時のハンドラ
     * @param event
     */
    const clickExcelOutputButtonHandler = async (event: any) => {
        setDisplayLoadingMark(true)
        setFlightDateFromErrorMessage("");
        setFlightDateToErrorMessage("");
        setDroneIdErrorMessage("")
        setInfoMessage("")
        setErrorMessage("")
        event.preventDefault();
        setFlightDateFromErrorMessage("");
        setFlightDateToErrorMessage("");

        let existError = false;
        if (droneRegistrationId === "") {
            setDroneIdErrorMessage("使用機材を指定してください");
            existError = true;
        }
        if (!flightDateFrom) {
            setFlightDateFromErrorMessage("開始日を入力してください");
            existError = true;
        }
        if (!flightDateTo) {
            setFlightDateToErrorMessage("終了日を入力してください");
            existError = true;
        }
        if (new Date(flightDateFrom) > new Date(flightDateTo)) {
            setFlightDateToErrorMessage("終了日は開始日以降の日付を入力してください");
            existError = true;
        }
        if (existError) {
            setDisplayLoadingMark(false);
            return;
        }

        // テンプレートファイルの呼び出し
        const res = await axios.get("/flightLogTemplate.xlsx", { responseType: "arraybuffer" });
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(res.data);


        let apigw: APIConnector = APIConnector.instance;
        // 出力する飛行実績を取得
        await apigw.getFlightLog(
            flightDateFrom,
            flightDateTo,
            droneRegistrationId,
            (fdSub == "指定なし" ? undefined : fdSub))
            .then(async (flightLogList) => {
                const flightLogWorksheet = workbook.getWorksheet('実績');
                const flightReportWorksheet = workbook.getWorksheet('記事');

                // ドローン情報
                flightLogWorksheet.getCell("C4").value = flightLogList[0].drone.registrationId;
                flightLogWorksheet.getCell("C5").value = flightLogList[0].drone.certification?.kind;
                flightLogWorksheet.getCell("C6").value = flightLogList[0].drone.certification?.type;
                flightLogWorksheet.getCell("C7").value = flightLogList[0].drone.certification?.number;
                flightLogWorksheet.getCell("C8").value = flightLogList[0].drone.designManufacturer;
                flightLogWorksheet.getCell("C9").value = flightLogList[0].drone.serialNumber;

                // フライトレポート数カウント
                let flightReportIndex = 0;

                for (let index = 0; index < flightLogList.length; index++) {
                    // フライト実績の表が開始する行
                    const flightLogStartRowNumber = 13;
                    // 飛行実績
                    let flightLogRow = flightLogWorksheet.getRow(flightLogStartRowNumber + index);
                    if (index > 8) {
                        // 10行目以降は行を追加する
                        flightLogWorksheet.insertRow(flightLogStartRowNumber + index, "", "i+");
                    }

                    flightLogRow.getCell(1).value = flightLogList[index].flightNum;
                    flightLogRow.getCell(2).value = flightLogList[index].yearMonthDate;
                    flightLogRow.getCell(3).value = flightLogList[index].fdName;
                    flightLogRow.getCell(4).value = flightLogList[index].competenceCertificateNumber;
                    flightLogRow.getCell(5).value = flightLogList[index].purpose;
                    flightLogRow.getCell(6).value = flightLogList[index].specificList?.join("、");
                    flightLogRow.getCell(7).value = flightLogList[index].flightPath.departure.portName + '\r\n'
                        + flightLogList[index].flightPath.departure.latitude + ","
                        + flightLogList[index].flightPath.departure.longitude;
                    flightLogRow.getCell(8).value = flightLogList[index].flightPath.arrival.portName + "\r\n"
                        + flightLogList[index].flightPath.arrival.latitude + ","
                        + flightLogList[index].flightPath.arrival.longitude;
                    flightLogRow.getCell(9).value = flightLogList[index].atd;
                    flightLogRow.getCell(10).value = flightLogList[index].ata;
                    flightLogRow.getCell(11).value = flightLogList[index].flightTime;
                    flightLogRow.getCell(12).value = flightLogList[index].totalFlightTime;
                    flightLogRow.getCell(13).value = flightLogList[index].affectedFlightSafetyMatters;

                    /**
                     * 現時点ではフライトにレポートが紐づいているため、往路・復路で重複して出力されてしまう
                     * 前のflightNumと同じの場合、出力しないようにする。((往路)(復路)は取り除く)
                     */
                    if (flightLogList[index].report
                        && (index === 0
                            || (index > 0 && flightLogList[index].flightNum.slice(0, 8)
                                !== flightLogList[index - 1].flightNum.slice(0, 8)))) {
                        console.log(flightLogList[index].report)
                        // フライトレポートの表が開始する行
                        const flightReportStartRowNumber = 3;
                        // フライトレポート
                        let flightReportRow = flightReportWorksheet.getRow(flightReportStartRowNumber + flightReportIndex);
                        if (flightReportIndex > 3) {
                            // 5行目以降は行を追加する
                            flightReportWorksheet.insertRow(flightReportStartRowNumber + flightReportIndex, "", "i+");
                        }
                        flightReportRow.getCell(1).value = flightLogList[index].report?.squawkDate;
                        flightReportRow.getCell(2).value = flightLogList[index].report?.flightSquawk;
                        flightReportRow.getCell(3).value = flightLogList[index].report?.actionDate;
                        flightReportRow.getCell(4).value = flightLogList[index].report?.correctiveAction;
                        flightReportRow.getCell(5).value = flightLogList[index].report?.confirmer;
                        flightReportIndex++;
                    };
                };

                // UInt8Arrayを生成
                const uint8Array = await workbook.xlsx.writeBuffer();
                // Blobを生成
                const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
                // DL用URLを生成し、aタグからダウンロードを実行
                const url = window.URL.createObjectURL(blob);
                // aタグを生成
                const a = document.createElement('a');
                // aタグのURLを設定
                a.href = url;
                // aタグにdownload属性を付け、URLがダウンロード対象になるようにする
                a.download = "補助者なし目視外飛行実績_" + flightDateFrom
                    + "～" + flightDateTo + ".xlsx";
                // aタグをクリック
                a.click();
                // ダウンロード後は不要なのでaタグを除去
                a.remove();
                setDisplayLoadingMark(false);
                // ダイアログを閉じる
                handleFlightLogOutputDialogClose();
            }).catch((error) => {
                setDisplayLoadingMark(false);
                console.log(error);
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    setErrorMessage("権限がありません");
                } else if (error.response
                    && error.response.data.reasonCode == "BBL0001"
                    && error.response.data.reason == "no target data") {
                    setInfoMessage("条件に該当する飛行実績はありません");
                } else {
                    setErrorMessage("システムエラーが発生しました");
                };
            });
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleFlightLogOutputDialogClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">飛行実績出力<Divider /></DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <Grid container direction="column" spacing={2}>
                    {infoMessage ?
                        <Grid item>
                            <Alert
                                severity="info">{infoMessage}</Alert>
                        </Grid>
                        : undefined
                    }
                    {errorMessage ?
                        <Grid item>
                            <Alert
                                severity="error">{errorMessage}</Alert>
                        </Grid>
                        : undefined
                    }
                    <Grid item>
                        <Typography>使用機材・出力対象期間を指定してください。【必須】</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            className={classes.textField}
                            id="drone"
                            select
                            label="使用機材"
                            variant="outlined"
                            value={droneRegistrationId}
                            onChange={handleChangeDrone}
                            error={droneIdErrorMessage ? true : false}
                            helperText={droneIdErrorMessage ? droneIdErrorMessage : ""}
                        >
                            {droneList ? droneList.map((drone: DroneInfo, i: number) => (
                                <MenuItem key={drone.registrationId} value={drone.registrationId}>{drone.label}</MenuItem>
                            )) : undefined}
                        </TextField>
                    </Grid>
                    <Grid item container direction="row" spacing={1}>
                        <Grid item>
                            <TextField
                                required
                                id="TargetPeriodFrom"
                                label="開始日"
                                type="date"
                                value={flightDateFrom}
                                variant="outlined"
                                className={classes.textField}
                                onChange={handleChangeFlightDateFrom}
                                error={flightDateFromErrorMessage ? true : false}
                                helperText={flightDateFromErrorMessage ? flightDateFromErrorMessage : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                id="TargetPeriodEnd"
                                label="終了日"
                                type="date"
                                value={flightDateTo}
                                variant="outlined"
                                className={classes.textField}
                                onChange={handleChangeFlightDateTo}
                                error={flightDateToErrorMessage ? true : false}
                                helperText={flightDateToErrorMessage ? flightDateToErrorMessage : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography> フライトディレクターを選択してください。【任意】</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.textField}
                            id="fd"
                            select
                            label="フライトディレクター"
                            variant="outlined"
                            value={fdSub}
                            onChange={handleChangeFD}
                        >
                            <MenuItem key={"指定なし"} value={"指定なし"} >指定なし</MenuItem>
                            {fdList ? fdList.map((fd, i) =>
                                <MenuItem key={fd.sub} value={fd.sub}>{fd.name}</MenuItem>
                            ) : undefined}
                        </TextField>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                        onClick={(e) => clickExcelOutputButtonHandler(e)}>
                        Excel出力
                    </Button>
                </DialogActions>
            </DialogContent>
            <Backdrop className={classes.backdrop} open={isDisplayLoadingMark}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
};