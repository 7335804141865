import React, { useState } from "react";
import { DroneInfo } from "adoms-common-lib";
import { ExpandLess, ExpandMore, ErrorTwoTone } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CardMedia, CardContent, Typography, CardActions, IconButton, Card, Collapse, Box, Chip, Divider } from "@mui/material";
import DroneDeleteDialog from "../organisms/DroneDeleteDialog";

type DroneCardViewProps = {
    droneData: DroneInfo;
    allDroneList: DroneInfo[];
    handleDroneUpdateDialogOpen: (drone: DroneInfo) => void;
    canEditDronetUser: boolean;
    setEditDroneList: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayLoadingMark: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DroneCardView: React.FC<DroneCardViewProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isImgError, setImgError] = useState<boolean>(false);
    const [deleteDroneData, setDeleteDroneData] = useState<DroneInfo>(props.droneData);
    const [isDroneDeleteDialogOpen, setDroneDeleteDialogOpen] = useState<boolean>(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDroneDeleteDialogOpen = (drone: DroneInfo) => {
        setDeleteDroneData(drone);
        setDroneDeleteDialogOpen(true);
    };

    const handleDroneDeleteDialogClose = () => {
        setDroneDeleteDialogOpen(false);
    };

    const setBusinessPartner = () => {
        return props.droneData.businessPartnerInfoList.map((businessPartnerInfo) => {
            return (
                <Chip
                    sx={{ margin: 0.3 }}
                    label={businessPartnerInfo.businessPartnerName} />
            );
        });
    };

    return (
        <>
            <Card key={props.droneData.registrationId} sx={{ borderRadius: '12px', boxShadow: 3 }}>
                {!props.droneData.img1 ?
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            color: 'rgba(0, 0, 0, 0.5)',
                            aspectRatio: "4 / 3"
                        }}
                    >
                        <ErrorTwoTone fontSize="large" color="disabled" />
                        <Box mt={3} sx={{ fontWeight: 'bold' }}>
                            機体画像は登録されていません
                        </Box>
                    </Box> :
                    isImgError ?
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                color: 'rgba(255, 0, 0, 0.5)',
                                aspectRatio: "4 / 3"
                            }}
                        >
                            <ErrorTwoTone fontSize="large" color="error" />
                            <Box mt={3} sx={{ fontWeight: 'bold' }}>
                                機体画像が取得できません
                            </Box>
                        </Box> :
                        <CardMedia
                            sx={{ flex: 0, borderRadius: '10px', aspectRatio: "4 / 3", objectFit: 'contain' }}
                            component="img"
                            image={props.droneData.img1}
                            title="drone img"
                            alt={props.droneData.label + "の機体全体像"}
                            onError={() => setImgError(true)}
                        />
                }
                <CardContent style={{ paddingBottom: "0px" }}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" fontWeight="fontWeightBold">
                            {props.droneData.label}
                        </Typography >
                    </Box>

                    <Box display="flex" justifyContent="space-between" style={{ paddingTop: "10px" }} alignItems="center">
                        <Typography variant="body1" fontWeight="bold" lineHeight={1} sx={{ whiteSpace: 'pre-line' }}>
                            {"無人航空機\n登録番号"}
                        </Typography>
                        <Typography lineHeight={1}>
                            {props.droneData.registrationId}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" fontWeight="bold" lineHeight={3}>
                            認証種別
                        </Typography>
                        <Typography lineHeight={3}>
                            {props.droneData.certification?.kind}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" fontWeight="bold" lineHeight={3}>
                            認証区分
                        </Typography>
                        <Typography lineHeight={3}>
                            {props.droneData.certification?.type}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" fontWeight="bold" lineHeight={3}>
                            認証番号
                        </Typography>
                        <Typography lineHeight={3}>
                            {props.droneData.certification?.number}
                        </Typography>
                    </Box>
                </CardContent>

                <CardActions disableSpacing>
                    {props.canEditDronetUser ?
                        <Box display="flex" justifyContent="space-between">
                            <IconButton color="primary">
                                <EditIcon onClick={() => { props.handleDroneUpdateDialogOpen(props.droneData) }} />
                            </IconButton>
                            <IconButton color="primary">
                                <DeleteIcon onClick={() => { handleDroneDeleteDialogOpen(props.droneData) }} />
                            </IconButton>
                        </Box>
                        : null
                    }
                    <Box justifyContent="flex-end" display="flex" width="100%">
                        <IconButton onClick={handleExpandClick} aria-labelledby="詳細">
                            {expanded ? <ExpandLess style={{ fontWeight: 'bold' }} /> : <ExpandMore style={{ fontWeight: 'bold' }} />}
                        </IconButton>
                    </Box>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit style={{ maxHeight: "350px", overflow: "auto" }}>
                    <CardContent style={{ paddingTop: "0px" }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography lineHeight={3}>
                                保有元
                            </Typography>
                            <Typography lineHeight={3}>
                                {props.droneData.owner}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography lineHeight={3}>
                                製造元
                            </Typography>
                            <Typography lineHeight={3}>
                                {props.droneData.designManufacturer}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography lineHeight={1} sx={{ whiteSpace: 'pre-line' }}>
                                {"シリアル\nナンバー"}
                            </Typography>
                            <Typography lineHeight={3}>
                                {props.droneData.serialNumber}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography lineHeight={3}>
                                最大積載量
                            </Typography>
                            <Typography lineHeight={3}>
                                {props.droneData.payload}g
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography lineHeight={3}>
                                機体種別
                            </Typography>
                            <Typography lineHeight={3}>
                                {props.droneData.kind}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography lineHeight={1} sx={{ whiteSpace: 'pre-line' }}>
                                {"ビジネス\nパートナー"}
                            </Typography>
                            <Typography lineHeight={2}>
                                <Box maxHeight={160} maxWidth={220} textAlign={"end"}>
                                    {setBusinessPartner()}
                                </Box>
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography lineHeight={3}>
                                備考
                            </Typography>
                            <Typography lineHeight={3}>
                                {props.droneData.remarks}
                            </Typography>
                        </Box>
                    </CardContent>
                </Collapse>
            </Card>

            {/** 機体情報を削除するダイアログ */}
            <DroneDeleteDialog
                isOpen={isDroneDeleteDialogOpen}
                closeHandler={handleDroneDeleteDialogClose}
                deleteDroneData={deleteDroneData}
                setEditDroneList={props.setEditDroneList}
                setDisplayLoadingMark={props.setDisplayLoadingMark} />
        </>
    );
};