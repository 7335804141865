import { Alert, Button, Dialog, MobileStepper, styled } from "@mui/material";
import { DroneInfo } from "adoms-common-lib";
import React, { useEffect, useState } from "react";
import DroneBasicInformationContent from "../molecule/DroneBasicInformationContent";
import DroneCertificationInformationContent from "../molecule/DroneCertificationInformationContent";
import DroneDetailInformationContent from "../molecule/DroneDetailInformationContent";
import { APIConnector } from "../../connector/APIConnector";
import axios from "axios";

// propsの型を定義
type DroneUpdateDialogporps = {
    isOpen: boolean;
    closeHandler: () => void;
    droneData?: DroneInfo;
    allDroneList: DroneInfo[] | undefined;
    setEditDroneList: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayLoadingMark: React.Dispatch<React.SetStateAction<boolean>>;
};

// MobileStepperのスタイル
const PortMobileStepper = styled(MobileStepper)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
}));

// ポート情報ダイアログ
const DroneUpdateDialog: React.FC<DroneUpdateDialogporps> = (props) => {
    const [editDrone, setEditDrone] = useState<DroneInfo>({
        registrationId: "",
        tenantId: "",
        sort: 0,
        label: "",
        kind: "",
        payload: 0,
        owner: "",
        businessPartnerInfoList: [],
        designManufacturer: "",
        serialNumber: ""
    });
    const [activeStep, setActiveStep] = useState(0);
    const [isNextDisable, setIsNextDisable] = useState(false);
    const [isConfirmDisable, setIsConfirmDisable] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    // ダイアログが閉じられたとき
    const handleClose = () => {
        setErrorMessage(undefined);
        props.closeHandler();
    };

    // 次へを押したとき
    const nextHandler = () => {
        setActiveStep((prevSteps) => prevSteps + 1);
    };

    // 戻るを押したとき
    const backHandler = () => {
        setActiveStep((prevSteps) => prevSteps - 1);
    };

    // ダイアログが閉じるたびにstate初期化
    useEffect(() => {
        setActiveStep((prevSteps) => (prevSteps = 0));
        setIsNextDisable(() => false);

        if (props.droneData) {
            let newDrone: DroneInfo = structuredClone(props.droneData);
            setEditDrone(newDrone);
        };
    }, [props.isOpen]);

    // 更新処理呼び出し
    const handleUpdate = async (event: any) => {
        if (props.droneData && editDrone) {
            props.setDisplayLoadingMark(true);
            let apigw: APIConnector = APIConnector.instance;
            await apigw.updateDrone(
                props.droneData,
                editDrone
            ).then(() => {
                props.closeHandler();
                props.setEditDroneList(true);
                setErrorMessage(undefined);
            }).catch((error) => {
                console.log(error);
                if (axios.isAxiosError(error) && typeof error.response !== "undefined" && error.response.status === 403) {
                    setErrorMessage("権限がありません");
                    props.setDisplayLoadingMark(false);
                } else {
                    setErrorMessage("DB更新に失敗しました");
                    props.setDisplayLoadingMark(false);
                };
            });
        };
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => {
                handleClose();
            }}
            sx={{ width: "auto" }}
            aria-labelledby="responsive-dialog-title"
        >
            {errorMessage ?
                <Alert severity="error" sx={{ margin: "20px" }}>{errorMessage}</Alert> :
                null}

            {/* ドローン基本情報 */}
            {activeStep === 0 && (
                <DroneBasicInformationContent
                    droneData={editDrone}
                    allDroneList={props.allDroneList}
                    setDroneData={setEditDrone}
                    setIsNextDisable={setIsNextDisable}
                />
            )}

            {/* ドローン詳細情報 */}
            {activeStep === 1 && (
                <DroneDetailInformationContent
                    droneData={editDrone}
                    setNewDrone={setEditDrone}
                    setIsNextDisable={setIsNextDisable}
                    isTextFieldDisabled={true}
                />
            )}


            {/* ドローン認証情報 */}
            {activeStep === 2 && (
                <DroneCertificationInformationContent
                    droneData={editDrone}
                    setNewDrone={setEditDrone}
                    setIsConfirmDisable={setIsConfirmDisable}
                />
            )}


            {/* ダイアログボタン関連 */}
            {/**後で更新処理に置き換え */}
            <PortMobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={activeStep}
                nextButton={
                    activeStep === 2 ? (
                        <Button
                            color="secondary"
                            size="small"
                            onClick={handleUpdate}
                            disabled={isConfirmDisable}
                        >
                            確定
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            size="small"
                            onClick={nextHandler}
                            disabled={isNextDisable}
                        >
                            次へ
                        </Button>
                    )}
                backButton={
                    <Button
                        color="primary"
                        size="small"
                        onClick={backHandler}
                        disabled={activeStep === 0}
                    >
                        戻る
                    </Button>
                }
            />
        </Dialog>
    );
};

export default DroneUpdateDialog;