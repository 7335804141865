import { ExpandLess } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FlagIcon from '@mui/icons-material/Flag';
import HistoryIcon from '@mui/icons-material/History';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LinkIcon from '@mui/icons-material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReportIcon from '@mui/icons-material/Report';
import TimelineIcon from '@mui/icons-material/Timeline';
import UpdateIcon from '@mui/icons-material/Update';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ReactComponent as DroneIcon } from "../../assets/icons/quadcopter.svg";
import { Avatar, Collapse, ListItemButton, SvgIcon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FunctionPrivilege, UserInfo } from 'adoms-common-lib';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { havePrivilege } from '../../common/PrivilegeUtil';
import { PushNotificationUtil, isIos } from '../../common/PushNotificationUtil';
import { useUserInfoContext } from '../../common/UserContext';
import { ErrorDialog } from './ErrorDialog';
import { FlightLogOutputDialog } from './FlightLogOutputDialog';
import { FlightTimeline } from './FlightTimeline';
import { ProfilePopover } from './ProfilePopover';

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  historyButton: {
    marginLeft: 'auto'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  historyDrawerPaper: {
    width: '100 %'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  flightHistorydrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  paper: {
    padding: '12px',
    height: '100%'
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
  avater: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));




type OperationMenuBarProps = {
  title: string,
  open: boolean,
  onChangeDrawerOpen: () => void,
  onChangeDrawerClose: () => void,
  visibleHisoty?: boolean,
  onChangeHistoryDrawerOpen?: () => void,
  onChangeHistoryDrawerClose?: () => void,
  isFlightHistory?: boolean,
  bgcolor?: string,
  flightId?: string,
  needUpdateTimeline?: boolean,
  setNeedUpdateTimeline?: React.Dispatch<React.SetStateAction<boolean>>,
  displayFlightHistoryFlag?: boolean,
  setDisplayFlightHistoryFlag?: React.Dispatch<React.SetStateAction<boolean>>
  updateTime?: string
  updateMessage?: string
}

/**
 * メニューバー
 */
const OperationMenuBar: React.FC<OperationMenuBarProps> = (props) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const userInfoContext = useUserInfoContext();
  //フライトログ出力のダイアログがオープンしているかどうかのフラグ
  const [isFlightLogOutputDialogOpen, setFlightLogOutputDialogOpen] = React.useState(false);
  const [isErrorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [privilegeOpen, setPrivilegeOpen] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(userInfoContext.userInfo);
  const [isUserInfoError, setUserInfoError] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [droneGeoInformationOpen, setDroneGeoInformationOpen] = React.useState(false);

  useEffect(() => {
    setUserInfo(userInfoContext.userInfo);
    setUserInfoError(userInfoContext.isError);
  }, [userInfoContext]);

  const handleToFlightOperation = (event: any) => {
    navigate({
      pathname: "/flightoperationview",
    })
  }

  const handleToFlightManagement = (event: any) => {
    navigate({
      pathname: "/flightlistview",
    })
  }

  const handleToOrderManagement = (event: any) => {
    navigate({
      pathname: "/orderlistview",
    })
  }

  const handleToPortManagement = (event: any) => {
    navigate({
      pathname: "/portlistview",
    })
  }

  const handleToDroneManagement = (event: any) => {
    navigate({
      pathname: "/DroneListView",
    })
  }

  const handleToUserInfo = (event: any) => {
    navigate({
      pathname: "/userlistview",
    })
  }

  const handleToPrivilege = (event: any) => {
    navigate({
      pathname: "/privilegelistview",
    })
  }

  /**
  * ドローン位置情報を表示するWebページを開く
  * @param event 
  * @param navigate 
  */
  const handleToDroneGeoInformation = (event: any) => {
    navigate({
      pathname: "/droneGeoInformationView"
    })
  }

  /**
  * マップURLを作成するWebページを開く
  * @param event 
  * @param navigate 
  */
  const handleToCreateMapURL = (event: any) => {
    navigate({
      pathname: "/mapURLListView"
    })
  }

  /**
* 配送依頼用URLを作成するWebページを開く
* @param event 
* @param navigate 
*/
  const handleToCreateCustomerURL = (event: any) => {
    navigate({
      pathname: "/customerURLListView"
    })
  }

  /**
  * フライト実績出力ダイアログを開く
  */
  const handleFlightLogOutputOpenDialog = () => {
    setFlightLogOutputDialogOpen(true);
  }

  /**
  * フライト実績出力ダイアログを閉じる
  */
  const handleFlightLogOutputCloseDialog = () => {
    setFlightLogOutputDialogOpen(false);
  }

  /**
   * 権限管理のメニューバーの詳細を押下した時
   */
  const handlePrivilegeClick = () => {
    setPrivilegeOpen(!privilegeOpen);
  };

  /**
   * ドローン位置情報のメニューバーの詳細を押下した時
   */
  const handleDroneGeoInformationClick = () => {
    setDroneGeoInformationOpen(!droneGeoInformationOpen);
  };

  /**
   * ヒヤリハット事象報告のGoogleフォームを開く
   */
  const handleReportNearMissAccidentFormOpen = () => {
    const url = 'https://forms.gle/RaK5J4nEZynihQkN8'
    window.open(url, '_blank')
  };

  const handleSignOut = async () => {
    try {
      /*
        サインアウト後は一切のAPI連携ができなくなるため、
        先にService Workerの消去とプッシュ通知関連の情報消去を行う。
        ログアウトに失敗した場合、再度プッシュ通知等を有効化したい場合は再接続する前提とする。
        尚、情報消去系のAPIのトークン認証の取り消しはセキュリティの観点から行わない。
      */
      if (!isIos() && Notification.permission === 'granted') {
        await PushNotificationUtil.instance.unregisterPushNotificationParameter();
      }
      await Auth.signOut();
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.log('error: ' + error);
      setErrorDialogOpen(true);
    }
  };

  const handleOpenProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={cx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
        style={
          props.bgcolor === undefined ? {} : { backgroundColor: props.bgcolor }
        }
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.onChangeDrawerOpen}
            edge="start"
            className={cx(classes.menuButton, props.open && classes.hide)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            ADOMS - {props.title}
          </Typography>
          {props.updateTime ?
            <React.Fragment>
              <UpdateIcon sx={{ marginLeft: 1 }} />
              <Typography variant="h6">
                {props.updateTime}
              </Typography>
              {
                props.updateMessage ? <Typography variant="h6" marginLeft={1}> {props.updateMessage} </Typography> : null
              }
            </React.Fragment> : undefined}
          <div className={classes.grow} />
          {props.visibleHisoty != undefined ?
            <IconButton
              color="inherit"
              aria-label="open historyDrawer"
              onClick={props.onChangeHistoryDrawerOpen}
              className={cx(classes.historyButton, props.visibleHisoty && classes.hide)}
              size="large">
              <HistoryIcon />
            </IconButton>
            : null}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenProfile}
            color="inherit"
            size="large">
            <Avatar className={classes.avater}>
              {userInfo?.name?.substring(0, 1)}
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={props.onChangeDrawerClose} size="large">
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>

        <Divider />
        <List>
          <ListItemButton key={"flight_operation"} onClick={(e) => { handleToFlightOperation(e) }}>
            <ListItemIcon><SupportAgentIcon /></ListItemIcon>
            <ListItemText primary="オペレーション" />
          </ListItemButton>
          <ListItemButton key={"flight_management"} onClick={(e) => { handleToFlightManagement(e) }}>
            <ListItemIcon><WatchLaterIcon /></ListItemIcon>
            <ListItemText primary="フライト管理" />
          </ListItemButton>
          <ListItemButton key={"delivery"} onClick={(e) => { handleToOrderManagement(e) }}>
            <ListItemIcon><ReceiptIcon /></ListItemIcon>
            <ListItemText primary="配送管理" />
          </ListItemButton>
          <ListItemButton key={"port_management"} onClick={(e) => { handleToPortManagement(e) }}>
            <ListItemIcon><FlagIcon /></ListItemIcon>
            <ListItemText primary="ポート管理" />
          </ListItemButton>
          <ListItemButton key={"drone_management"} onClick={(e) => { handleToDroneManagement(e) }}>
            <ListItemIcon>
              <SvgIcon
                style={{
                  color: "#737373",
                }}>
                <DroneIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="機体管理" />
          </ListItemButton>
          <ListItemButton key={"drone_geo_information"} onClick={(e) => { handleDroneGeoInformationClick() }}>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <ListItemText primary="ドローン位置情報" />
            {droneGeoInformationOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={droneGeoInformationOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton key={"map_display"} className={classes.nested} onClick={(e) => { handleToDroneGeoInformation(e) }}>
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary="マップ" />
              </ListItemButton>
              <ListItemButton key={"map_url_create"} className={classes.nested} onClick={(e) => { handleToCreateMapURL(e) }}>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="マップURL一覧" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton key={"customer_url_create"} onClick={(e) => { handleToCreateCustomerURL(e) }}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="配送依頼用URL一覧" />
          </ListItemButton>
          <ListItemButton key={"output_flightlog"} onClick={(e) => { handleFlightLogOutputOpenDialog() }}>
            <ListItemIcon><TimelineIcon /></ListItemIcon>
            <ListItemText primary="飛行実績出力" />
          </ListItemButton>

          {havePrivilege(FunctionPrivilege.PrivilegeFunctionUpdateUserInfo, userInfo) ?
            <React.Fragment>
              <ListItemButton key={"user_management"} onClick={handlePrivilegeClick}>
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="ユーザー管理" />
                {privilegeOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={privilegeOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton key={"user_management"} className={classes.nested} onClick={(e) => { handleToUserInfo(e) }}>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="ユーザー一覧" />
                  </ListItemButton>
                  <ListItemButton key={"privilege_list"} className={classes.nested} onClick={(e) => { handleToPrivilege(e) }}>
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="権限一覧" />
                  </ListItemButton>
                </List>
              </Collapse>
            </React.Fragment>
            :
            <ListItemButton key={"user_management"} onClick={(e) => { handleToUserInfo(e) }}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="ユーザー一覧" />
            </ListItemButton>
          }
          <ListItemButton key={"report_near_miss_accident"} onClick={(e) => { handleReportNearMissAccidentFormOpen() }}>
            <ListItemIcon><ReportIcon /></ListItemIcon>
            <ListItemText primary="ヒヤリハット報告" />
          </ListItemButton>
        </List>

        <Divider />

        <List>
          <ListItemButton key={"logoff"} onClick={(e) => { handleSignOut() }}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItemButton>
        </List>
      </Drawer>
      {props.flightId
        && props.needUpdateTimeline !== undefined
        && props.setNeedUpdateTimeline
        && props.displayFlightHistoryFlag !== undefined
        && props.setDisplayFlightHistoryFlag ?
        <Drawer
          variant="persistent"
          anchor="right"
          classes={{
            paper: classes.historyDrawerPaper,
          }}
          open={props.visibleHisoty}
        >
          <div className={classes.flightHistorydrawerHeader}>
            <IconButton onClick={props.onChangeHistoryDrawerClose} size="large">
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <FlightTimeline
              key={props.flightId}
              flightId={props.flightId} />
          </List>
        </Drawer>
        : null}

      {/** 新しい配送情報を作成するダイアログ */}
      <FlightLogOutputDialog
        isOpen={isFlightLogOutputDialogOpen}
        onClose={handleFlightLogOutputCloseDialog}
      />

      <ErrorDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setErrorDialogOpen}
        errorMessage={"ログアウト処理に失敗しました。\nしばらく経ってからもう一度やり直してください。"}
      />

      <ProfilePopover
        key="profile-popover"
        anchorEl={anchorEl}
        handleCloseProfile={handleCloseProfile}
        userInfo={userInfo}
        isUserInfoError={isUserInfoError}
      />
    </div>
  );
}

export default OperationMenuBar;